export const MESSAGES_INFO = "MESSAGES_INFO";
export const MESSAGES_INFO_SUCCESS = "MESSAGES_INFO_SUCCESS";
export const MESSAGES_INFO_FAILED = "MESSAGES_INFO_FAILED";

export const SEND_MESSAGE = "SEND_MESSAGE";
export const SEND_MESSAGE_FAILED = "SEND_MESSAGE_FAILED";
export const SEND_MESSAGE_SUCCESS = "SEND_MESSAGE_SUCCESS";

export const NEW_MESSAGES_RECEIVED = "NEW_MESSAGES_RECEIVED";
export const HIDE_NEW_MESSAGES = "HIDE_NEW_MESSAGES";

export const CLEAR_MESSAGES_STORE = "CLEAR_MESSAGES_STORE";