
import React, { PureComponent } from "react";
import store from "../../redux/store";
import { connect } from "react-redux";
import config from "../../system/config";
import { withRouter, NavLink } from "react-router-dom";
import SendMessageForm from "./components/forms/SendMessageForm";
import Sprite from "../../assets/img/symbol/sprite.svg";
import NewMessagesPopup from "./components/popups/NewMessagesPopup";
import NotificationMethodPopup from "./components/popups/NotificationMethodPopup";
import CloseFeedbackPopup from "./components/popups/CloseFeedbackPopup";
import { Modal } from "antd";
import Textarea from "react-textarea-autosize";
import DialogFooter from "./components/DialogFooter";
import moment from "moment";
import pusher from "../../system/services/PusherWrapper";

import OptionalDataPopup from "./components/popups/OptionalDataPopup";

import {
    REMOVE_FIRST_MESSAGE,
    OPEN_NOTIFICATION_METHOD_POPUP,
    UNIVERSITY_QUESTIONS,
    SAVE_UNIVERSITY_QUESTIONS
} from "../../redux/actions/accountActions";

import {
    MESSAGES_INFO,
    SEND_MESSAGE,
    NEW_MESSAGES_RECEIVED,
    CLEAR_MESSAGES_STORE
} from "../../redux/actions/messagesActions";

import {
    FLAG_DIALOG,
    CLOSE_DIALOG,
    DIALOGS_INFO
} from "../../redux/actions/dialogsActions";

import {
    OPEN_ACTION_MODAL_CHILD,
    CLOSE_ACTION_MODAL_CHILD
} from "../../redux/actions/componentsActions";
import { Mixpanel } from "../../system/services/MixpanelService";

const $ = require("jquery");

class Messaging extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            openClosePopup: false,
            reportedReason: "",
            flagFieldError: false
        };

        this.handleSendMessage = this.handleSendMessage.bind(this);
        this.handleSurePopupClick = this.handleSurePopupClick.bind(this);
        this.handleFlagClick = this.handleFlagClick.bind(this);
        this.handleGoBack = this.handleGoBack.bind(this);
        this.handleReopenDialog = this.handleReopenDialog.bind(this);
        this.closeDialog = this.closeDialog.bind(this);
        this.handleClosePopup = this.handleClosePopup.bind(this);
        this.followMessage = this.followMessage.bind(this);
        this.handleFlagTextChange = this.handleFlagTextChange.bind(this);
        this.handleSubmitOptionalQuestions = this.handleSubmitOptionalQuestions.bind(this);
    }

    componentDidMount() {
        localStorage.setItem("activeDialog", this.props.match.params.dialog_id);

        store.dispatch({ type: DIALOGS_INFO });

        store.dispatch({
            type: MESSAGES_INFO,
            payload: {
                dialog_id: this.props.match.params.dialog_id,
                func: (messages) => {
                    const channel = pusher.subscribe("dashboard");
                    const dialog_id = this.props.match.params.dialog_id;
                    channel.bind("messages:new:" + messages.univercity.id, function(data) {
                        const element = document.getElementById("chat-body");
                        $(document).find(".chat-nav-msg-counter").remove("hidden-block");

                        if (element) {
                            if (data.dialog_id == dialog_id) {
                                store.dispatch({
                                    type: MESSAGES_INFO,
                                    payload: {
                                        dialog_id: data.dialog_id,
                                        func: () => {
                                            element.scrollTop = element.scrollHeight;
                                        }
                                    }
                                });
                            }
                        } else {
                            if (data.dialog_id == dialog_id) {
                                store.dispatch({
                                    type: NEW_MESSAGES_RECEIVED
                                });
                            }
                        }
                    });

                    channel.bind("messages:reported:" + messages.univercity.id, function(data) {
                        if (data.dialog_id == dialog_id) {
                            store.dispatch({
                                type: DIALOGS_INFO
                            });
                        }
                    });

                    channel.bind("messages:new:" + messages.univercity.id, function(data) {
                        if (data.dialog_id == dialog_id) {
                            store.dispatch({
                                type: DIALOGS_INFO
                            });
                        }
                    });

                    channel.bind("dialogs:close:" + messages.univercity.id, function(data) {
                        if (data.dialog_id == dialog_id) {
                            store.dispatch({
                                type: DIALOGS_INFO
                            });
                        }
                    });
                }
            }
        });

        if (this.props.firstMessage) {
            store.dispatch({
                type: SEND_MESSAGE,
                payload: {
                    type: "text",
                    text: this.props.firstMessage,
                    dialog_id: this.props.match.params.dialog_id
                }
            });

            if (!this.props.notificationData) {
                store.dispatch({
                    type: OPEN_NOTIFICATION_METHOD_POPUP
                });
            }

            store.dispatch({
                type: REMOVE_FIRST_MESSAGE
            });
        }
    }

    componentDidUpdate() {
        const element = document.getElementById("chat-body");
        if (element) {
            element.scrollTop = element.scrollHeight;
        }
    }

    componentWillUnmount() {
        store.dispatch({
            type: CLEAR_MESSAGES_STORE
        });
    }

    followMessage(messages, members) {
        const account_info = this.props.account.account_info;
        if (messages.length < 2) return null;

        const lastMessage = messages[messages.length - 1];
        if (lastMessage.user_id != account_info.id) return null;

        const [ambassador] = members.filter(item => item.id != this.props.account.account_info.id);
        return (
            <div className="system-message">
                { ambassador.name } will reply as soon as they can, and you will receive a notification.
            </div>
        );
    }

    handleSendMessage() {
        const { messages, dialogs } = this.props;
        if (document.getElementById("content-messaging-textarea").value.replace(/^\s+|\s+$/g, "") === "") return;

        $("#content-messaging-textarea").removeAttr("style");

        if (messages && messages.items && dialogs.items && messages.items.length === 1) {
            const [dialog] = dialogs.items.filter(dialog => {
                return dialog.id == this.props.match.params.dialog_id;
            });

            Mixpanel.track("Start Dialog", {
                Dialog: dialog.id
            });
        }

        store.dispatch({
            type: SEND_MESSAGE,
            payload: {
                type: "text",
                text: $("#content-messaging-textarea").val(),
                dialog_id: this.props.match.params.dialog_id
            }
        });

        $("#content-messaging-textarea").val("");

        if (!this.props.notificationData) {
            store.dispatch({
                type: OPEN_NOTIFICATION_METHOD_POPUP
            });
        }
    }

    handleFlagClick() {
        if (this.state.reportedReason.length === 0) {
            this.setState({
                flagFieldError: true
            });
            if (this.state.reportedReason.trim().length === 0) {
                $("#text-close-flag").addClass("error-flag-field");
            } else {
                $("#text-close-flag").removeClass("error-flag-field");
            }
        } else {
            this.handleClosePopup();
            store.dispatch({
                type: FLAG_DIALOG,
                payload: {
                    dialog_id: this.props.match.params.dialog_id,
                    reported_reason: this.state.reportedReason
                }
            });

            $(".icon.icon-flag").toggleClass("status-icon-active");
        }
    }

    closeDialog() {
        this.handleClosePopup();
        const [dialog] = this.props.dialogs.items.filter(dialog => {
            return dialog.id == this.props.match.params.dialog_id;
        });

        if (!dialog.archived) {
            Mixpanel.track("Close Dialog", {
                Dialog: dialog.id
            });
            store.dispatch({
                type: CLOSE_DIALOG,
                payload: {
                    dialog_id: this.props.match.params.dialog_id
                }
            });

            $(".icon.icon-close").toggleClass("status-icon-active");
            this.setState({ openClosePopup: true });
        }
    }

    handleFlagTextChange(event) {
        this.setState({
            reportedReason: event.target.value
        });
        $("#text-size-flagged").text(event.target.value.length);
        if (event.target.value.trim().length === 0) {
            $("#text-close-flag").addClass("error-flag-field");
            $("#text-size-flagged").addClass("error-flag-count");
        } else {
            $("#text-close-flag").removeClass("error-flag-field");
            $("#text-size-flagged").removeClass("error-flag-count");
        }
    }

    handleSurePopupClick(method, subtitle, isFlag) {
        console.log("ttt");
        let textareaBlock = "";
        if (isFlag) {
            textareaBlock =
          <div className="text-close-flag-block">
              <Textarea
                  onKeyUp={this.handleFlagTextChange}
                  minRows={1}
                  maxRows={3}
                  maxLength="140"
                  id="text-close-flag"
                  type="text"
                  placeholder="What went wrong ?"
                  className="text-close-flag"
              />
              <span className="text-size-flagged"><span id="text-size-flagged">0</span>/{140}</span>
          </div>;
        }
        store.dispatch({
            type: OPEN_ACTION_MODAL_CHILD,
            payload: {
                children: (
                    <div className="general-popup" id="verification" >
                        <h2 className="modal-title">Are you sure?</h2>
                        {subtitle}
                        {textareaBlock}
                        <div className={"btn-wrap confirm-ban-buttons" + (isFlag ? " flag-close-buttons" : "")}>
                            <button type="submit" className="el-btn general-action-button" onClick={this[method]} >Confirm</button>
                            <button type="submit" className="el-btn general-action-button cancel" onClick={this.handleClosePopup} >Cancel</button>
                        </div>
                    </div>
                )
            }
        });
    }

    handleClosePopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL_CHILD
        });
    }

    createLinksInMessages(text) {
        return (text || "").replace(
            /([^\S]|^)(((https?\:\/\/)|(www\.))(\S+))/gi,
            function(match, space, url) {
                var hyperlink = url;
                if (!hyperlink.match("^https?:\/\/")) {
                    hyperlink = "https://" + hyperlink;
                }
                return space + "<a href=\"" + hyperlink + "\">" + url + "</a>";
            }
        );
    };

    handleGoBack() {
        const dialog = this.props.dialogs.items.filter(dialog => {
            return dialog.id == this.props.match.params.dialog_id;
        });
        let user_id = "";
        if (dialog[0] && dialog[0].first_ambassador && dialog[0].first_ambassador.id) {
            user_id = "/" + dialog[0].first_ambassador.id;
        }
        if (!sessionStorage.getItem("goBack")) {
            window.location = `${config.tap_tape_url2}${dialog[0].university.id}`;
        } else {
            const page = sessionStorage.getItem("goBack");
            sessionStorage.removeItem("goBack");
            window.location = page;
        }
    }

    handleReopenDialog() {
        this.handleClosePopup();
        store.dispatch({
            type: CLOSE_DIALOG,
            payload: {
                dialog_id: this.props.match.params.dialog_id
            }
        });

        $(".icon.icon-close").toggleClass("status-icon-active");
    }

    dialogData() {
        if (!this.props.dialogs.items ||
            !this.props.dialogs.items.length) {
            return null;
        }

        const currentDialogId = Number.parseInt(this.props.match.params.dialog_id);

        const [dialog] = this.props.dialogs.items.filter(dialog => {
            return dialog.id === currentDialogId;
        });

        return {
            ambassador: dialog.first_ambassador,
            university: dialog.university,
            hasAccepted: dialog.universityQuestionsAnswered === true
        };
    }

    loadOptionalQuestions(universityId) {
        store.dispatch({
            type: UNIVERSITY_QUESTIONS,
            payload: universityId
        });
    }

    handleSubmitOptionalQuestions(answers) {
        const universityId = this.props.messages.univercity.id;

        store.dispatch({
            type: SAVE_UNIVERSITY_QUESTIONS,
            payload: {
                universityId,
                answers
            }
        });
    }

    render() {
        if (this.props.messages.isLoading || this.props.dialogs.isLoading) return null;

        localStorage.setItem("universityCode", this.props.messages.univercity.id);

        const dialog = this.props.dialogs.items.filter(dialog => {
            return dialog.id == this.props.match.params.dialog_id;
        });

        dialog[0].members.sort((a, b) => new Date(a.created_at).getTime() - new Date(b.created_at).getTime());

        const messagesRender = this.props.messages.items.map(message => {
            if (message.type == "text") {
                if (!message.content.text) return null;

                const created_at = moment(message.created_at).calendar(null, {
                    sameDay: "H:mm",
                    nextDay: "[Tomorrow]",
                    nextWeek: "[Next] dddd",
                    lastDay: "[Yesterday at ]H:mm",
                    lastWeek: "DD MMMM",
                    sameElse: "DD MMMM"
                });

                const array = message.content.text.split("[badword]");
                const urlRegExpression = new RegExp("([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?");

                array.map((it, i) => {
                    if (urlRegExpression.test(it)) {
                        array[i] = this.createLinksInMessages(it);
                    }
                    if (it.includes("[")) {
                        if (message.hide_keywords) { array[i] = "***" + it.split("[/badword]")[1]; } else { array[i] = it.split("[/badword]")[0] + " " + it.split("[/badword]")[1]; }
                    }
                });

                const text = array.join("");

                let publisher = {};
                dialog[0].members.map(val => {
                    if (val.id == message.user_id) publisher = val;
                });
                dialog[0].prospect.map(val => {
                    if (val.id == message.user_id) publisher = val;
                });
                dialog[0].admin.map(val => {
                    if (val.id == message.user_id) publisher = val;
                });

                publisher.name = publisher.name;
                return <div key={message.id} className={this.props.account.account_info.id == message.user_id ? "msg-item mod-my" : "msg-item"}>

                    {this.props.account.account_info.id == message.user_id

                        ? <div className="msg-avatar-block">
                            {publisher.name.slice(0, 1)}
                        </div>

                        : <div className="msg-avatar-block">
                            <img className="msg-item-img" src={publisher.avatar ? (publisher.avatar.sizes["70x70"] || publisher.avatar.original) : "https://www.chaarat.com/wp-content/uploads/2017/08/placeholder-user.png"} alt={publisher.name + "'s avatar"} />
                        </div>

                    }
                    <div className="msg-block-item">
                        <p className="msg-name">{publisher.name}</p>
                        <p className="msg-text" dangerouslySetInnerHTML={{ __html: text }} />
                        <div className="msg-controls">
                            <p className="msg-time">{created_at}</p>
                        </div>
                    </div>
                </div>;
            } else if (message.type == "joined") {
                const { user, user_avatar, ambassador, ambassador_avater, university, ambassador_type } = message.content;

                return <div key={message.id} className="join-row">
                    <div className="msg-avatar-block">
                        <img className="msg-item-img" src={user_avatar} alt={user + "'s avatar"}/>
                    </div>
                    <p className="join-text">{user} added</p>
                    <div className="msg-avatar-block">
                        <img className="msg-item-img" src={ambassador_avater} alt={ambassador + "'s avatar"}/>
                    </div>
                    <p className="join-text">
                        {ambassador}
                        {ambassador_type ? ", " + university + " " + ambassador_type + ", " : " from " + university + " "}
                    to the chat
                    </p>
                </div>;
            } else if (message.type == "joined-admin") {
                const adminName = message.content.user;
                return <div key={message.id} className="join-row">
                    <div className="msg-avatar-block">
                        <img className="msg-item-img" src={message.content.avatar} alt={adminName + "'s avatar"}/>
                    </div>
                    <p className="join-text">{adminName} joined the chat</p>
                </div>;
            } else if (message.type == "invite") {
                const { user, user_avatar, admin, admin_avatar, university } = message.content;
                return <div key={message.id} className="join-row">
                    <div className="msg-avatar-block">
                        <img className="msg-item-img" src={user_avatar} alt={user + "'s avatar"}/>
                    </div>
                    <p className="join-text">{user} added</p>
                    <div className="msg-avatar-block">
                        <img className="msg-item-img" src={admin_avatar} alt={admin + "'s avatar"}/>
                    </div>
                    <p className="join-text">{admin}, {university} staff representative to the chat.</p>
                </div>;
            }
        });

        const membersView = dialog[0].members.map((member, index) => {
            member.name = member.name.split(" ")[0];
            var online = member.last_online ? moment.duration(moment().diff(moment(member.last_online.updated_at))).asMinutes() : 10;

            return <div key={member.id} className="chat-name-head-row">
                <div className={online < 5 ? "chat-name-img-container mod-online" : "chat-name-img-container"}>
                    <div className="chat-name-img-block">
                        <img className="chat-name-img" src={member.avatar ? (member.avatar.sizes["70x70"] || member.avatar.original) : "https://www.chaarat.com/wp-content/uploads/2017/08/placeholder-user.png"} alt={member.name + "'s avatar"} />
                    </div>
                </div>
                <div className="chat-name-block">
                    <h3 className="chat-name">{member.name}</h3>
                    <p className="chat-members">
                        {member.user_tags.courses_types ? member.user_tags.courses_types[0].name + " " : ""}
                        {member.user_tags.subject ? member.user_tags.subject[0].name + " " : ""}
                        {member.user_tags.job_role ? member.user_tags.job_role[0].name + " " : ""}
                        {member.user_tags.industry ? member.user_tags.industry[0].name : ""}
                        {member.user_tags.staff ? member.user_tags.staff[0].name + " Staff" : ""}
                    </p>
                </div>
            </div>;
        });

        const flagModalChild = <div className="sub-title">If you flag this conversation, it will be reviewed by an administrator at { this.props.messages.univercity.name }.</div>;
        const closeModalChild = <div className="sub-title">By closing this conversation, you will no longer be able to chat with {dialog[0].members[0].name}.</div>;

        const dialogData = this.dialogData();

        return (
            <div id="page-body" className="page-body js-index">
                <div className={"chat " + (this.state.openClosePopup ? "blur" : "")} >
                    <div className="chat-block">
                        <div className="chat-head">
                            <div className="chat-head-left">
                                <button type="button" className="chat-back-btn" onClick={this.handleGoBack}>
                                    <svg className="icon icon-back">
                                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={Sprite + "#back"}></use>
                                    </svg>
                                </button>
                                {membersView}
                            </div>
                            <div className="chat-head-right">
                                <button type="button" disabled={dialog[0].user_reported || dialog[0].reported} className="chat-control" onClick={() => { this.handleSurePopupClick("handleFlagClick", flagModalChild, true); }}>

                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-4 -2 24 24" width="32" height="32" preserveAspectRatio="xMinYMin" className="jam jam-flag" fill={(dialog[0].user_reported || dialog[0].reported) ? "#ff5100" : "#262e45"}>
                                        <path d='M2 1h12a2 2 0 0 1 2 2v7a2 2 0 0 1-2 2H2v7a1 1 0 0 1-2 0V1a1 1 0 1 1 2 0zm0 9h12V3H2v7z'/>
                                    </svg>
                                    {
                                        dialog[0].user_reported || dialog[0].reported
                                            ? <span className="toggle-left toggle-left--flagged">Conversation is flagged</span>
                                            : <span className="toggle-left">Flag conversation</span>
                                    }
                                </button>

                                { !dialog[0].archived ? <button type="button" className="chat-control chat-close" onClick={() => { this.handleSurePopupClick("closeDialog", closeModalChild); }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" width="32" height="32" preserveAspectRatio="xMinYMin" className="jam jam-close"><path d='M5.094 16.32A8 8 0 0 0 16.32 5.094L5.094 16.32zM3.68 14.906L14.906 3.68A8 8 0 0 0 3.68 14.906zM10 20C4.477 20 0 15.523 0 10S4.477 0 10 0s10 4.477 10 10-4.477 10-10 10z'/></svg>
                                    <span className="toggle-left">Close conversation</span>
                                </button> : null }

                                <NavLink to="/settings">
                                    <button type="button" className="chat-control">
                                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" width="32" height="32" preserveAspectRatio="xMinYMin" className="jam jam-cog"><path d='M20 8.163A2.106 2.106 0 0 0 18.926 10c0 .789.433 1.476 1.074 1.837l-.717 2.406a2.105 2.105 0 0 0-2.218 3.058l-2.062 1.602A2.104 2.104 0 0 0 11.633 20l-3.29-.008a2.104 2.104 0 0 0-3.362-1.094l-2.06-1.615A2.105 2.105 0 0 0 .715 14.24L0 11.825A2.106 2.106 0 0 0 1.051 10C1.051 9.22.63 8.54 0 8.175L.715 5.76a2.105 2.105 0 0 0 2.207-3.043L4.98 1.102A2.104 2.104 0 0 0 8.342.008L11.634 0a2.104 2.104 0 0 0 3.37 1.097l2.06 1.603a2.105 2.105 0 0 0 2.218 3.058L20 8.162zM14.823 3.68c0-.063.002-.125.005-.188l-.08-.062a4.103 4.103 0 0 1-4.308-1.428l-.904.002a4.1 4.1 0 0 1-4.29 1.43l-.095.076A4.108 4.108 0 0 1 2.279 7.6a4.1 4.1 0 0 1 .772 2.399c0 .882-.28 1.715-.772 2.4a4.108 4.108 0 0 1 2.872 4.09l.096.075a4.104 4.104 0 0 1 4.289 1.43l.904.002a4.1 4.1 0 0 1 4.307-1.428l.08-.062A4.108 4.108 0 0 1 17.7 12.4a4.102 4.102 0 0 1-.773-2.4c0-.882.281-1.716.773-2.4a4.108 4.108 0 0 1-2.876-3.919zM10 14a4 4 0 1 1 0-8 4 4 0 0 1 0 8zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4z'/></svg>
                                        <span className="toggle-left">Account settings</span>
                                    </button>
                                </NavLink>
                            </div>
                        </div>
                        <div className="chat-body" id="chat-body">
                            <div className="chat-body-list">
                                {messagesRender}
                                { this.followMessage(this.props.messages.items, dialog[0].members) }
                                <NewMessagesPopup dialog_id={dialog[0].id} />
                            </div>
                        </div>

                        <DialogFooter
                            dialog={dialog[0]}
                            handleSurePopupClick={() => { this.handleSurePopupClick("handleReopenDialog"); }}>

                            <SendMessageForm onSubmit={this.handleSendMessage} />

                        </DialogFooter>

                    </div>
                </div>
                <Modal
                    visible={this.props.notificationMethod.isOpened}
                    centered={true}
                    footer={null}
                    closable={false}
                    width={430}
                    className="notification-popup"
                    maskClosable={false}
                >
                    <NotificationMethodPopup
                        universityId={this.props.messages.univercity.id}
                        prospectEmail={this.props.account.account_info.email}
                    />
                </Modal>
                <Modal
                    visible={!dialogData.hasAccepted && this.props.account.showOptionalQuestions}
                    centered={true}
                    footer={null}
                    closable={false}
                    maskClosable={false}
                    width={500}
                    className="optional-questions-popup"
                >
                    <OptionalDataPopup
                        ambassador={dialogData.ambassador}
                        university={dialogData.university}
                        loadOptionalQuestions={this.loadOptionalQuestions}
                        questions={this.props.account.quizQuestion}
                        countries={this.props.account.countries}
                        specializations={this.props.account.specializations}
                        startOptions={this.props.account.tags}
                        onSubmit={this.handleSubmitOptionalQuestions}
                    />
                </Modal>
                <Modal
                    visible={this.state.openClosePopup}
                    centered={true}
                    footer={null}
                    closable={false}
                    width={430}
                    className="notification-popup"
                    maskClosable={false}
                >
                    <CloseFeedbackPopup dialog={dialog} handleCloseReport={() => this.setState({ openClosePopup: false })} />
                </Modal>
            </div>
        );
    }
}

export default connect(state => {
    return {
        account: state.account,
        notificationData: state.account.notificationData,
        notificationMethod: state.notificationMethodPopup,
        messages: state.messages,
        dialogs: state.dialogs,
        firstMessage: state.registrationPopup.firstMessage
    };
})(withRouter(Messaging));
