import React, {PureComponent} from 'react';
import {reduxForm, Field} from 'redux-form';

const renderField = ({input, labelClass, caption, placeholder, type, className, readOnly, meta: {touched, error, warning}}) => {
    return (
        <label className={labelClass}>
            <p className="modal-label-text">{caption}</p>
            <input {...input} className={className} readOnly={readOnly} placeholder={placeholder} type={type}/>
            {touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
        </label>
    )};

class LoginForm extends PureComponent {
    render() {
        const { handleSubmit, onReset } = this.props;

        return(
            <div>
                <div className="sign-in-title">Sign in</div>
                <form className="sign-up-form" onSubmit={handleSubmit}>
                    <Field
                        name='email'
                        component={renderField}
                        type='email'
                        placeholder='Email address'
                        caption='Email address'
                        labelClass='sign-up-form-label'
                        className="modal-input"
                    />
                    <Field
                        name='password'
                        component={renderField}
                        type='password'
                        placeholder='Password'
                        caption='Password'
                        labelClass='sign-up-form-label'
                        className="modal-input"
                    />

                    <p className="reset-link" onClick={ onReset }>Recover password</p>

                    <button type="submit" className="sign-up-sbm">Sign in</button>
                </form>
            </div>
        )
    }
}

export default reduxForm({
    form: 'login_form_2'
})(LoginForm);
