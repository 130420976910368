import Users from './users';
import Dialogs from './dialogs';
import Account from './account';
import Messages from './messages';

export default {
    users: Users,
    dialogs: Dialogs,
    account: Account,
    messages: Messages
};
