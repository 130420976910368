export const REGISTRATION = "REGISTRATION";
export const REGISTRATION_SUCCESS = "REGISTRATION_SUCCESS";
export const REGISTRATION_FAILED = "REGISTRATION_FAILED";

export const OPEN_REGISTRATION_POPUP = "OPEN_REGISTRATION_POPUP";
export const CLOSE_REGISTRATION_POPUP = "CLOSE_REGISTRATION_POPUP";

export const NEXT_REGISTRATION_STEP = "NEXT_REGISTRATION_STEP";

export const OPEN_LOGIN_POPUP = "OPEN_LOGIN_POPUP";
export const CLOSE_LOGIN_POPUP = "CLOSE_LOGIN_POPUP";

export const LOGIN = "LOGIN";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILED = "LOGIN_FAILED";

export const ACCOUNT_INFO = "ACCOUNT_INFO";
export const ACCOUNT_INFO_SUCCESS = "ACCOUNT_INFO_SUCCESS";
export const ACCOUNT_INFO_FAILED = "ACCOUNT_INFO_FAILED";

export const UNIVERSITY_QUESTIONS = "UNIVERSITY_QUESTIONS";
export const UNIVERSITY_QUESTIONS_SUCCESS = "UNIVERSITY_QUESTIONS_SUCCESS";
export const UNIVERSITY_QUESTIONS_FAILED = "UNIVERSITY_QUESTIONS_FAILED";

export const SAVE_UNIVERSITY_QUESTIONS = "SAVE_UNIVERSITY_QUESTIONS";
export const SAVE_UNIVERSITY_QUESTIONS_SUCCESS = "SAVE_UNIVERSITY_QUESTIONS_SUCCESS";
export const SAVE_UNIVERSITY_QUESTIONS_FAILED = "SAVE_UNIVERSITY_QUESTIONS_FAILED";

export const GET_COUNTRIES = "GET_COUNTRIES";
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS";
export const GET_COUNTRIES_FAILED = "GET_COUNTRIES_FAILED";

export const GET_NATIONALITIES = "GET_NATIONALITIES";
export const GET_NATIONALITIES_SUCCESS = "GET_NATIONALITIES_SUCCESS";
export const GET_NATIONALITIES_FAILED = "GET_NATIONALITIES_FAILED";

export const ACCOUNT_CHANGE_AVATAR = "ACCOUNT_CHANGE_AVATAR";
export const ACCOUNT_CHANGE_AVATAR_SUCCESS = "ACCOUNT_CHANGE_AVATAR_SUCCESS";

export const CHANGE_MAILING = "CHANGE_MAILING";

export const SET_FIRST_MESSAGE = "SET_FIRST_MESSAGE";
export const REMOVE_FIRST_MESSAGE = "REMOVE_FIRST_MESSAGE";

export const OPEN_NOTIFICATION_METHOD_POPUP = "OPEN_NOTIFICATION_METHOD_POPUP";
export const CLOSE_NOTIFICATION_METHOD_POPUP = "CLOSE_NOTIFICATION_METHOD_POPUP";
export const NOTIFICATION_METHOD_POPUP_STEP = "NOTIFICATION_METHOD_POPUP_STEP";
