import {
    CLOSE_ACTION_MODAL,
    OPEN_ACTION_MODAL,
    CLOSE_ACTION_MODAL_CHILD,
    OPEN_ACTION_MODAL_CHILD
} from '../../actions/componentsActions';  

import {
    ACTION_MODAL
} from '../../store/initialState';

const initialState = ACTION_MODAL;

export default function (state = initialState, action) {
    const type = action.type;
    const payload = action.payload;

    switch(type) {
        case CLOSE_ACTION_MODAL: {
            return initialState;
        }

        case CLOSE_ACTION_MODAL_CHILD: {
            let new_state = Object.assign({}, initialState);
            new_state.withChild.isOpened = false;
            new_state.withChild.children = null;
            return new_state;
        }

        case OPEN_ACTION_MODAL: {
            let new_state = Object.assign({}, state);
            new_state.isOpened = true;
            new_state.title = payload.title;
            new_state.body = payload.body;
            new_state.children = payload.children;
            return new_state;
        }

        case OPEN_ACTION_MODAL_CHILD: {
            let new_state = Object.assign({}, initialState);
            new_state.withChild.isOpened = true;
            new_state.withChild.children = payload.children;
            return new_state;
        }
        
        default: {
            return state;
        }
    }
}
  