import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import EditNameForm from './forms/EditNameForm'
import API from "../../api";
import {NavLink} from "react-router-dom";
import store from '../../redux/store';
import { ACCOUNT_CHANGE_AVATAR, CHANGE_MAILING } from '../../redux/actions/accountActions'
import config from '../../system/config'

import {
  OPEN_ACTION_MODAL_CHILD,
  OPEN_ACTION_MODAL,
  CLOSE_ACTION_MODAL_CHILD
} from '../../redux/actions/componentsActions'
import messages from '../../api/messages';

class Settings extends PureComponent {

  constructor() {
    super()
    this.state = {
      visibleName: false,
      visibleEmail: false,
      visiblePassword: false,
      visibleLastName: false
    }
    this.clearVisible = this.clearVisible.bind(this);
    this.changeMailing = this.changeMailing.bind(this);
    this.handleChangeAvatarInput = this.handleChangeAvatarInput.bind(this);
    this.handleSurePopupClick = this.handleSurePopupClick.bind(this);
    this.deleteUser = this.deleteUser.bind(this);
    this.getUniversityCode = this.getUniversityCode.bind(this);
  }

  clearVisible () {
    this.setState({
      visibleName: false,
      visibleEmail: false,
      visiblePassword: false
    })
  }

  changeMailing (e) {
    const checked = JSON.parse(JSON.stringify(e.currentTarget.checked));

    API.account.changeMailing(checked)
    .then(() => {
      store.dispatch({
        type: CHANGE_MAILING,
        payload: {
          mailing: checked
        }
      })
    })
  }


  handleChangeAvatarInput(event) {
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = function() {
      store.dispatch({
        type: ACCOUNT_CHANGE_AVATAR,
        payload: {
          avatar: reader.result.substr(reader.result.indexOf(",") + 1)
        }
      });
    };
  }

  handleSurePopupClick(subtitle) {
    store.dispatch({
      type: OPEN_ACTION_MODAL_CHILD,
      payload: {
        children:  (
          <div className="general-popup"  >
              <h2 className="modal-title">Are you sure?</h2>
              <div className="btn-wrap confirm-ban-buttons">
                <button type="submit" className="el-btn general-action-button" onClick={this.deleteUser} >Confirm</button>
                <button type="submit" className="el-btn general-action-button cancel" onClick={this.handleClosePopup} >Cancel</button>
              </div>
          </div>
        )
      }
    })
  }

  handleClosePopup() {
    store.dispatch({
      type: CLOSE_ACTION_MODAL_CHILD
    })
  }

  async deleteUser () {
    await API.account.deleteUser()
    localStorage.setItem('TapAccountInteractionSite', null)
    localStorage.setItem('pusherTransportTLS', null)
    const universityCode = this.getUniversityCode()
    localStorage.setItem('universityCode', null)
    document.body.innerHTML += '<form id="delete-form" action=' + config.tap_tape_url2 + universityCode +' method="GET"></form>'
    document.getElementById('delete-form').submit()
  }

  getUniversityCode () {
    return localStorage.getItem('universityCode')
  }

  handleSignOut() {
    localStorage.setItem('universityCode', null)
    localStorage.setItem('TapAccountInteractionSite', null)
    localStorage.setItem('pusherTransportTLS', null)
    window.location = window.location.origin
  }

  render() {

    let avatar

    if (this.props.account.account_info.avatar) {
      avatar = this.props.account.account_info.avatar
      if (avatar.sizes["70x70"]) avatar = avatar.sizes["70x70"]
      else avatar = avatar.original

    }
    return(
        <div id="page-body" className="page-body js-index">
        <div className="chat">
          <div className="chat-block">
            <div className="chat-head">
              <div className="chat-head-left">
                <h1 className="chat-list-page">Settings</h1>
                <NavLink className="settings-back" to={"/dialogs/" + localStorage.getItem('activeDialog')}>Back</NavLink>
              </div>

            </div>
            <div className="settings">
              <div className="settings-name-row">
                <p className="settings-name">First name</p>
                <button
                  onClick={() => { this.setState({visibleName: !this.state.visibleName})}}
                  type="button"
                  className="change-btn js-change-name"
                >
                  Change
                </button>
              </div>
              <label className="settings-label">
                <p className="settings-static-text">{this.props.account.account_info.name}</p>
                <EditNameForm
                  isVisible={this.state.visibleName}
                  placeholder="Account name"
                  value={this.props.account.account_info.name}
                  name="name"
                  handleSubmit="changeName"
                  callback={this.clearVisible}
                />
              </label>

              <div className="settings-name-row">
                <p className="settings-name">Last name</p>
                <button
                  onClick={() => { this.setState({visibleLastName: !this.state.visibleLastName})}}
                  type="button"
                  className="change-btn js-change-name"
                >
                  Change
                </button>
              </div>
              <label className="settings-label">
                <p className="settings-static-text">{this.props.account.account_info.last_name}</p>
                <EditNameForm
                  isVisible={this.state.visibleLastName}
                  placeholder="Last name"
                  value={this.props.account.account_info.last_name}
                  name="last_name"
                  handleSubmit="changeLastName"
                  callback={this.clearVisible}
                />
              </label>

              <div className="settings-name-row">
                <p className="settings-name">Email address</p>
                <button
                  onClick={() => { this.setState({visibleEmail: !this.state.visibleEmail})}}
                  type="button"
                  className="change-btn js-change-name"
                >Change</button>
              </div>
              <label className="settings-label">
                <p className="settings-static-text">{this.props.account.account_info.email}</p>
                <EditNameForm
                  isVisible={this.state.visibleEmail}
                  placeholder="Email address"
                  value={this.props.account.account_info.email}
                  name="email"
                  handleSubmit="changeEmail"
                  callback={this.clearVisible}
                />
              </label>
              <div className="settings-name-row">
                <p className="settings-name">Password</p>
                <button
                  onClick={() => { this.setState({visiblePassword: !this.state.visiblePassword})}}
                  type="button"
                  className="change-btn js-change-name"
                >Change</button>
              </div>
              <label className="settings-label">
                <p className="settings-static-text">******</p>
                <EditNameForm
                  isVisible={this.state.visiblePassword}
                  placeholder="Password"
                  value=""
                  type="password"
                  name="email"
                  handleSubmit="changePassword"
                  callback={this.clearVisible}
                />
                <input type="password" className="settings-input mod-password" name="name" value="123456" />
              </label>
              <div className="settings-name-row">
                <p className="settings-name">Marketing preferences</p>
              </div>
              <label className="settings-label">
                <p className="settings-static-text">
                  <input onChange={this.changeMailing} defaultChecked={this.props.account.account_info.mailing} type="checkbox"></input>
                  <span>I agree to receive marketing communications via email</span>
                </p>
              </label>
              <button onClick={this.handleSignOut} type="button" className="delete-btn sign-out-btn">Sign out</button>
              <button onClick={this.handleSurePopupClick} type="button" className="delete-btn">Delete account</button>
                <div className="settings-name-row">
                  <NavLink className="settings-back right-button" to={"/dialogs/" + localStorage.getItem('activeDialog')}>Back to chat</NavLink>
                </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect( state => {
  return {
    account: state.account,
    dialogs: state.dialogs,
    messages: state.messages
  }
})(withRouter(Settings));
