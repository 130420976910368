import HttpService from "../../system/services/HttpService";

export default {
    get: () => {
        return HttpService.get("/interactionSite/account/info");
    },
    login: (email, password) => {
        return HttpService.post("/interactionSite/auth/login", {
            email: email,
            password: password
        });
    },
    universityQuestions: (university_id) => {
        return HttpService.get("/interactionSite/auth/tellAboutYouQuestions/" + university_id);
    },
    saveUniversityQuestions: ({ universityId, answers }) => {
        return HttpService.post("/interactionSite/auth/" + universityId + "/optionalQuestionsAnswers", { answers });
    },
    registration: ({
        email,
        password,
        first_name,
        last_name,
        receive_email,
        communications,
        nationality_id,
        university_id,
        utm_medium,
        utm_source,
        utm_campaign,
        cameFrom
    }) => {
        return HttpService.post("/interactionSite/auth/registration", {
            email,
            password,
            name: first_name,
            last_name,
            resive_email: receive_email,
            communications,
            nationality_id,
            university_id,
            medium: utm_medium,
            source: utm_source,
            campaign: utm_campaign,
            user_came_from: cameFrom
        });
    },

    getCountries: () => {
        return HttpService.get("/interactionSite/tags/subtags/_countries");
    },

    getNationalities: () => {
        return HttpService.get("/interactionSite/nationalities");
    },

    checkEmail(email) {
        return HttpService.get("/interactionSite/auth/unique/email", { email: email });
    },

    changeName(name) {
        return HttpService.put("/interactionSite/account/edit/changeName", { name });
    },

    changeLastName(last_name) {
        return HttpService.put("/interactionSite/account/edit/changeLastName", { last_name });
    },

    changeEmail(email) {
        return HttpService.get("/interactionSite/account/edit/email", { email });
    },

    changeEmailConfirm(email, verification_code) {
        return HttpService.post("/interactionSite/account/edit/email", { email, verification_code });
    },

    changePassword(password) {
        return HttpService.get("/interactionSite/account/edit/password", { password });
    },

    changePasswordConfirm(new_password, verification_code) {
        return HttpService.post("/interactionSite/account/edit/password", {
            new_password,
            verification_code,
            confirm_password: new_password
        });
    },

    changeAvatar: (avatar) => {
        return HttpService.put("/interactionSite/account/edit/avatar", {
            file: avatar
        });
    },

    changeMailing(mailing) {
        return HttpService.put("/interactionSite/account/edit/changeMailing", { mailing });
    },

    deleteUser() {
        return HttpService.put("/interactionSite/account/delete");
    },

    startRecovery(email) {
        return HttpService.post("/interactionSite/account/recovery/password", {
            email
        });
    },

    recovery(user_id, hex, new_password, confirm_password) {
        return HttpService.post("/interactionSite/account/recovery/passwordConfirm", {
            user_id, hex, new_password, confirm_password
        });
    },

    authByCode(authCode) {
        return HttpService.get("/interactionSite/auth/login/" + authCode);
    },

    sendNotificationMethod(notificationData) {
        return HttpService.put("/interactionSite/account/notificationMethod", notificationData);
    },

    sendVerificationCode(verificationCode) {
        return HttpService.put("/interactionSite/account/confirmNotificationMethod", verificationCode);
    }
};
