import React, { PureComponent } from "react";
import store from "../../../../redux/store";
import { connect } from "react-redux";
import {
    SET_DATA_REGISTER_MODAL
} from "../../../../redux/actions/componentsActions";
import Dropdown from "../../../../components/Dropdown";

const getMinimumAgeForNationality = (nationality) => {
    let minimumAge = 13;

    switch (nationality) {
    case "Austrian":
    case "Bulgarian":
    case "Cypriot":
    case "Italian":
    case "Lithuanian":
    case "Spanish":
        minimumAge = 14;
        break;

    case "Czech":
    case "French":
    case "Greek":
        minimumAge = 15;
        break;

    case "Croatian":
    case "German":
    case "Hungarian":
    case "Irish":
    case "Luxembourger":
    case "Dutch":
    case "Polish":
    case "Romanian":
    case "Slovak":
    case "Slovenian":
        minimumAge = 16;
        break;
    }

    return minimumAge;
};

class Step1 extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            first_name: null,
            last_name: null,
            nationality: {},
            prevNationalities: null,
            minimumAge: 13,
            email: null,
            privacy: null,
            errors: {}
        };

        this.submit = this.submit.bind(this);
        this.validation = this.validation.bind(this);
        this.setField = this.setField.bind(this);
        this.setFieldCheckbox = this.setFieldCheckbox.bind(this);
        this.onSelectNationality = this.onSelectNationality.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        const alreadySelected = (props.registrationPopup.data && props.registrationPopup.data["1"] && props.registrationPopup.data["1"].nationality);
        if (!alreadySelected && !state.nationality.id && props.nationalities &&
            props.nationalities !== state.prevNationalities &&
            props.nationalities.length && props.nationalities[0].autoDefined) {
            state.prevNationalities = props.nationalities;

            state.nationality = props.nationalities[0];
            state.minimumAge = getMinimumAgeForNationality(props.nationalities[0].name);

            store.dispatch({
                type: SET_DATA_REGISTER_MODAL,
                payload: {
                    key: "nationality",
                    val: { id: props.nationalities[0].id, name: props.nationalities[0].name }
                }
            });

            return state;
        }

        return null;
    }

    validation(values) {
        const errors = {};
        if (!values.email) {
            errors.email = "Please enter an email address";
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,16}$/i.test(values.email)) {
            errors.email = "Invalid email address";
        }

        if (!values.first_name) {
            errors.first_name = "Please enter your first name";
        } else if (values.first_name.length === 1) {
            errors.first_name = "First name must contain at least 2 letters";
        }

        if (!values.last_name) {
            errors.last_name = "Please enter your last name";
        } else if (values.last_name.length === 1) {
            errors.last_name = "Last name must contain at least 2 letters";
        }

        if (!values.nationality_id) {
            errors.nationality = "Please select a nationality";
        }

        if (!values.password) {
            errors.password = "Please choose a password";
        } else if (values.password.length < 6) {
            errors.password = "Your password should be at least 6 characters long";
        }

        if (!values.privacy) {
            errors.privacy = "Field required";
        }
        if (Object.keys(errors).length) {
            this.setState({ errors });
            return false;
        } else {
            return true;
        }
    }

    componentDidMount() {
        const data = this.props.registrationPopup.data["1"];

        const newState = {};
        Object.keys(data).map(key => {
            newState[key] = data[key];
        });

        if (newState.nationality) {
            newState.minimumAge = getMinimumAgeForNationality(newState.nationality.name);
        }

        this.setState(newState);
    }

    submit() {
        const data = {
            first_name: this.state.first_name,
            last_name: this.state.last_name,
            email: this.state.email,
            nationality_id: this.state.nationality.id,
            privacy: this.state.privacy,
            password: this.state.password
        };
        if (this.validation(data)) { this.props.onSubmit(data); }
    }

    setField(e) {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
        store.dispatch({
            type: SET_DATA_REGISTER_MODAL,
            payload: {
                key: e.currentTarget.name,
                val: e.currentTarget.value
            }
        });
    }

    setFieldCheckbox(e) {
        this.setState({ [e.currentTarget.name]: e.currentTarget.checked });
        store.dispatch({
            type: SET_DATA_REGISTER_MODAL,
            payload: {
                key: e.currentTarget.name,
                val: e.currentTarget.checked
            }
        });
    }

    onSelectNationality(nationality) {
        this.setState({
            nationality: nationality,
            minimumAge: getMinimumAgeForNationality(nationality.name)
        });

        store.dispatch({
            type: SET_DATA_REGISTER_MODAL,
            payload: {
                key: "nationality",
                val: { id: nationality.id, name: nationality.name }
            }
        });
    };

    renderAlternative(universityId) {
        if (universityId !== 189) {
            return null;
        }

        return (
            <p className="checkbox-text">
                Alternatively you can email UTS at <a href="mailto:international@uts.edu.au">international@uts.edu.au</a> if you choose not to consent.
            </p>
        );
    }

    render() {
        const { errors, nationality } = this.state;
        const { university, emailError, nationalities, privacyLink } = this.props;

        return (
            <div className="sign-up-form">
                <div className="sign-up-form-row">
                    <label className="sign-up-form-label">
                        <input
                            name='first_name'
                            type='text'
                            placeholder='First name'
                            className="modal-input"
                            value={this.state.first_name || ""}
                            onChange={this.setField}
                            aria-label="Enter your first name"
                            aria-required="true"
                        />
                        { errors.first_name ? <span className="error">{errors.first_name}</span> : null}
                    </label>
                    <label className="sign-up-form-label">
                        <input
                            name='last_name'
                            type='text'
                            placeholder='Last name'
                            className="modal-input"
                            value={this.state.last_name || ""}
                            onChange={this.setField}
                            aria-label="Enter your last name"
                            aria-required="true"
                        />
                        { errors.last_name ? <span className="error">{errors.last_name}</span> : null}
                    </label>
                </div>

                { nationalities.length ? (
                    <div className="field">
                        <label className="label sign-up-form-label">
                            <div className="control">
                                <Dropdown items={nationalities} selectedItem={nationality} placeholder={"Select Nationality"} type={"nationality"} onSelect={this.onSelectNationality} />
                                { errors.nationality ? <span className="error">{errors.nationality}</span> : null}
                            </div>
                        </label>
                    </div>

                ) : null }

                <label className="sign-up-form-label">
                    <input
                        name='email'
                        type='text'
                        placeholder='Email address'
                        className="modal-input"
                        value={this.state.email || ""}
                        onChange={this.setField}
                        aria-label="Enter your email address"
                        aria-required="true"
                    />

                    { emailError ? <span className="error">This email is already taken</span> : null }
                    { errors.email ? <span className="error">{errors.email}</span> : null}
                </label>

                <label className="sign-up-form-label">
                    <input
                        name='password'
                        type='password'
                        placeholder='Password'
                        className="modal-input"
                        value={this.state.password || ""}
                        onChange={this.setField}
                        aria-label="Enter a password"
                        aria-required="true"
                    />
                    { errors.password ? <span className="error">{errors.password}</span> : null}
                </label>

                <label className="check-label">
                    <input
                        type="checkbox"
                        className="checkbox"
                        name="privacy"
                        value={this.state.privacy || false}
                        onChange={this.setFieldCheckbox}
                        checked={this.state.privacy || false}
                        aria-label="Please agree to the terms"
                        aria-required="true"
                    />
                    <span className="checkbox-icon" />
                    <p className="checkbox-text">
                    I am over the age of {this.state.minimumAge} and consent to my personal data being processed by {university.name} according to their <a href={privacyLink} target="_blank">Privacy Policy</a> and being shared with The Access Platform according to their <a href="https://tap.st/privacy" target="_blank">Privacy Notice</a> and <a href="https://tap.st/user-terms" target="_blank">terms</a> so that they can facilitate my messaging interactions with {university.name}
                    </p>

                    { errors.privacy ? <span className="error">{errors.privacy}</span> : null}
                </label>

                {this.renderAlternative(university.id)}

                <button onClick={this.submit} className="sign-up-sbm">Start conversation</button>
            </div>
        );
    }
}
export default connect(state => {
    return {
        registrationPopup: state.registrationPopup
    };
})(Step1);
