import { connect } from 'react-redux';
import React, {PureComponent} from 'react';

import img1 from '../../../../assets/svg/1.svg'
import img2 from '../../../../assets/svg/2.svg'
import img3 from '../../../../assets/svg/3.svg'

import store from '../../../../redux/store';
import {
    AFTER_DIALOG_REPORT
} from '../../../../redux/actions/dialogsActions';
import {Mixpanel} from "../../../../system/services/MixpanelService";

var $ = require("jquery");

class NewMessagesPopup extends PureComponent {

    constructor(props) {
        super(props);

        this.handlePopupClick = this.handlePopupClick.bind(this);
        this.handleSetRate = this.handleSetRate.bind(this);
        this.state = {
            rate: null
        }
    }

    handlePopupClick(e) {
        if (this.state.rate === null) return

        if(this.props.dialog && this.props.dialog[0]) {
            Mixpanel.track("Gave Feedback", {
                "Dialog": this.props.dialog[0].id
            });
        }
        store.dispatch({
            type: AFTER_DIALOG_REPORT,
            payload: {
              dialog_id: this.props.dialog[0].id,
              user_mark: this.state.rate,
              university_mark: e.currentTarget.dataset.value
            }
        })
        this.handleCloseReport()
    }

    handleSetRate(rate) {
        this.setState({rate})
    }

    render() {
        const {dialog, handleCloseReport} = this.props;
        this.handleCloseReport = handleCloseReport.bind(this)
        if (dialog[0].members[0]) var member = dialog[0].members[0];

        return (
            <div className="remodal close-conversation" id="close-conversation" data-remodal-id="close-conversation">
                <h2 className="modal-name">Thanks for chatting!</h2>
                <p className="modal-subtext">Please rate your conversation with {member ? member.name : 'this ambassador'}</p>
                <div className="rate-row">

                    <label className="rate-label" onClick={() => this.handleSetRate(-1)}>
                        <input type="radio" className="radio-input" name="rate" />
                        <img className="rate-icon" src={img3} alt="Rate the conversation: bad" />
                    </label>

                    <label className="rate-label" onClick={() => this.handleSetRate(0)}>
                        <input type="radio" className=" radio-input" name="rate" />
                        <img className="rate-icon" src={img2} alt="Rate the conversation: average" />
                    </label>

                    <label className="rate-label" onClick={() => this.handleSetRate(1) }>
                        <input type="radio" className="radio-input" name="rate" />
                        <img className="rate-icon" src={img1} alt="Rate the conversation: good" />
                    </label>
                </div>
                <label className="modal-label mod-center">
                    <p className="modal-label-text">Do you feel more confident as a result of this conversation?</p>
                    <div className="radio-row">
                      <button data-value={false} onClick={this.handlePopupClick} className="sign-up-sbm">No</button>
                      <button data-value={true} onClick={this.handlePopupClick} className="sign-up-sbm">Yes</button>

                    </div>
                </label>
            </div>
        )
    }
}

export default connect( state => {
    return {
        messages: state.messages
    }
})(NewMessagesPopup);
