import React, {PureComponent} from 'react';

class ActionPopupChild extends PureComponent {
    
    render() {
        const {handleClose} = this.props;

        if(this.props.children)
            return this.props.children
        else 
            return ''
    }
}

export default ActionPopupChild;
