export const DIALOGS_INFO = "DIALOGS_INFO";
export const DIALOGS_INFO_SUCCESS = "DIALOGS_INFO_SUCCESS";
export const DIALOGS_INFO_FAILED = "DIALOGS_INFO_FAILED";

export const CLOSE_DIALOG = "CLOSE_DIALOG";
export const CLOSE_DIALOG_SUCCESS = "CLOSE_DIALOG_SUCCESS";
export const CLOSE_DIALOG_FAILED = "CLOSE_DIALOG_FAILED";

export const FLAG_DIALOG = "FLAG_DIALOG";
export const FLAG_DIALOG_SUCCESS = "FLAG_DIALOG_SUCCESS";
export const FLAG_DIALOG_FAILED = "FLAG_DIALOG_FAILED";

export const FIND_OR_CREATE_DIALOG = "FIND_OR_CREATE_DIALOG";
export const FIND_OR_CREATE_DIALOG_FAILED = "FIND_OR_CREATE_DIALOG_FAILED";
export const FIND_OR_CREATE_DIALOG_SUCCESS = "FIND_OR_CREATE_DIALOG_SUCCESS";

export const AFTER_DIALOG_REPORT = "AFTER_DIALOG_REPORT"
export const AFTER_DIALOG_REPORT_FAILED = "AFTER_DIALOG_REPORT_FAILED"
export const AFTER_DIALOG_REPORT_SUCCESS = "AFTER_DIALOG_REPORT_SUCCESS"