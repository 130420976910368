import {
    OPEN_NOTIFICATION_METHOD_POPUP,
    CLOSE_NOTIFICATION_METHOD_POPUP,
    NOTIFICATION_METHOD_POPUP_STEP

} from "../../actions/accountActions";

import { NOTIFICATION_METHOD_POPUP } from "../../store/initialState";

export default function(state = NOTIFICATION_METHOD_POPUP, action) {
    switch (action.type) {
    case OPEN_NOTIFICATION_METHOD_POPUP: {
        return {
            ...state,
            isOpened: true
        };
    }

    case CLOSE_NOTIFICATION_METHOD_POPUP: {
        return {
            ...state,
            isOpened: false
        };
    }

    case NOTIFICATION_METHOD_POPUP_STEP: {
        return {
            ...state,
            step: action.payload
        };
    }

    default:
        return state;
    }
}
