import React, { Component } from 'react';
import chevronDown from "../assets/img/svg/chevron-down.svg";

class Dropdown extends Component {
    constructor(props) {
        super(props);

        this.state = {
            initialItems: [],
            items: [],
            isOpen: false,
            name: ''
        };
        this.search = this.search.bind(this);
        this.handleClickOutside = this.handleClickOutside.bind(this)
    }

    componentDidMount() {
        document.addEventListener('click', this.handleClickOutside);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { initialItems } = this.state;
        const { items } = this.props;
        if(initialItems.length !== items.length) {
            this.setState({
                initialItems: items,
                items: items
            })
        }
    }

    componentWillUnmount() {
        document.removeEventListener('click', this.handleClickOutside);
    }

    handleClickOutside(e) {
        let { type } = this.props;
        if(this.state.isOpen) {
            let element = document.getElementById('dropdown-' + type);
            let targetElement = e.target; // clicked element
            do {
                if (targetElement == element) {
                    return;
                }
                // Go up the DOM
                targetElement = targetElement.parentNode;
            } while (targetElement);
            this.setState({
                isOpen: false
            });
        }
    }

    search(e) {
        const { initialItems } = this.state;
        const { onSelect } = this.props;
        let itemsNew = [];
        if(initialItems) {
            for (let spec of initialItems) {
                if(spec.name.toLowerCase().includes(e.target.value.toLowerCase())) {
                    itemsNew.push(spec)
                }
            }
        }
        this.setState({
            items: itemsNew,
            isOpen: itemsNew.length > 0,
            name: e.target.value
        }, onSelect({}));

    }

    onKeyDown = (e) => {
        let { items, name } = this.state;
        let { selectedItem, type, onSelect } = this.props;
        if (e.key === 'Enter' && name.trim().length > 1) {
            e.preventDefault();
            let activeNew = {};
            if(items) {
                for (let spec of items) {
                    if(spec.name === e.target.value) {
                        activeNew = spec;
                    }
                }
            }
            this.setState({
                isOpen: false
            }, onSelect(activeNew));
        } else if(e.key === 'ArrowDown' || e.key === 'ArrowUp'){
            let selectedItemNew = {};
            if(items) {
                for(let index in items) {
                    if ((items[index].id === selectedItem.id || !selectedItem.id)) {
                        if (e.key === 'ArrowUp') {
                            selectedItemNew = items[index - 1] && selectedItem.id ? items[index - 1] : items[index];
                        } else {
                            selectedItemNew = items[+index + 1] && selectedItem.id ? items[+index + 1] : items[index];
                        }
                        break;
                    }
                }
            }

            this.setState({
                name: selectedItemNew.name ? selectedItemNew.name : ''
            }, onSelect(selectedItemNew));
            try {
                let activeElement = null;
                let globalTagsElem = document.getElementById('dropdown-menu-' + type);
                if(globalTagsElem) {
                    activeElement = globalTagsElem.getElementsByClassName('dropdown-item--selected')[0]
                }
                if (activeElement) {
                    let container = activeElement.parentNode;
                    let offset = activeElement.offsetTop; // get element's y position in list
                    offset -= container.scrollTop; // subtract list's scroll position to get relative position

                    let delta_to_bottom = offset + activeElement.clientHeight - container.clientHeight; // calculate distance from active element's bottom to the list's bottom
                    if (delta_to_bottom > 0) { // if distance is greater than 0, it must be out of view
                        container.scrollTop += e.key !== 'ArrowUp' ? delta_to_bottom + 35 : delta_to_bottom // add delta to view
                    }

                    // same goes for top
                    let delta_to_top = offset + activeElement.clientHeight - container.clientHeight;
                    if (delta_to_top < 0) {
                        container.scrollTop += e.key === 'ArrowUp' ? delta_to_top - 35 : delta_to_top
                    }
                }
            } catch(err) {}
        }
    };


    toggleMenu = () => {
        this.setState({
            isOpen: !this.state.isOpen
        })
    };

    onSelect = (e, item) => {
        const { onSelect } = this.props;
        e.preventDefault();
        this.setState({
            name: item.name,
            isOpen: false
        }, onSelect(item));
    };

    render() {
        const { isOpen, items } = this.state;
        const { selectedItem, type, placeholder } = this.props;
        return (
            <div id={'dropdown-' + type} className='dropdown-custom'>
                <div
                    className={isOpen ? 'input-wrapper input-wrapper-open' : 'input-wrapper'}
                    onClick={this.toggleMenu}
                >
                    <input
                        onClick={(e) => {isOpen && e.stopPropagation()}}
                        className='dropdown-input'
                        value={selectedItem.name}
                        onChange={this.search}
                        onKeyDown={this.onKeyDown}
                        placeholder={placeholder ? placeholder : `Select or type to search`}
                    />
                    <img className={isOpen ? 'chevron chevron-open' : 'chevron'} src={chevronDown} alt='' onClick={this.toggleMenu}/>
                </div>
                <div className={isOpen ? 'menu-wrapper menu-wrapper-open' : 'menu-wrapper'}>
                    <div id={'dropdown-menu-' + type} className='dropdown-menu'>
                        { (items && items.length) &&
                        items.map(item =>
                            <div
                                key={item.id}
                                onClick={(e) => this.onSelect(e, item)}
                                className={'dropdown-item'+(selectedItem.id && item.id === selectedItem.id ? ' dropdown-item--selected' : '')}
                            >
                                {item.name}
                            </div>
                        )
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Dropdown;
