export const ACCOUNT = {
    token: JSON.parse(localStorage.getItem("TapAccountInteractionSite")),
    account_info: null,
    startUser: null,
    countries: [],
    nationalities: [],
    showOptionalQuestions: true
};

export const REGISTER_POPUP = {
    isOpened: false,
    step: 1,
    data: {
        1: {},
        2: {}
    },
    firstMessage: null
};

export const DIALOGS = {
    isLoading: true,
    items: null,
    redirectDialogId: null
};

export const MESSAGES = {
    isLoading: true,
    items: null,
    univercity: null,
    isNewMessagesModalOpened: false
};

export const LOGIN_POPUP = {
    isOpened: false
};

export const NOTIFICATION_METHOD_POPUP = {
    isOpened: false,
    step: 1
};

export const ACTION_MODAL = {
    title: null,
    body: null,
    children: null,
    isOpened: false,
    withChild: {
        children: null,
        isOpened: false
    }
};
