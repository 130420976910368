import React, { PureComponent } from "react";
import { Modal, Overlay } from "react-modal-construction-kit";
import store from "../../redux/store";
import { connect } from "react-redux";
import { withRouter, Redirect } from "react-router-dom";
import config from "../../system/config";
import ChatComponent from "./components/chat";
import RegisterComponent from "./components/register";
import LoginComponent from "./components/auth/index";

import {
    OPEN_REGISTRATION_POPUP,
    CLOSE_REGISTRATION_POPUP,
    CLOSE_LOGIN_POPUP,
    OPEN_LOGIN_POPUP,
    ACCOUNT_INFO
} from "../../redux/actions/accountActions";

import {
    STUDENT_INFO
} from "../../redux/actions/userActions";

import {
    FIND_OR_CREATE_DIALOG
} from "../../redux/actions/dialogsActions";

class Register extends PureComponent {
    constructor(props) {
        super(props);

        this.handleGoBack = this.handleGoBack.bind(this);
    }

    componentDidMount() {
        const student_id = this.props.match.params.student_id;

        store.dispatch({
            type: STUDENT_INFO,
            payload: {
                student_id: student_id
            }
        });

        if (this.props.account.token) {
            store.dispatch({
                type: ACCOUNT_INFO
            });

            store.dispatch({
                type: FIND_OR_CREATE_DIALOG,
                payload: {
                    student_id: this.props.match.params.student_id
                }
            });
        }
    }

    handleRegisterClick() {
        store.dispatch({
            type: OPEN_REGISTRATION_POPUP
        });
    }

    handleCloseRegisterPopup() {
        store.dispatch({
            type: CLOSE_REGISTRATION_POPUP
        });
    }

    handleLoginClick() {
        store.dispatch({
            type: OPEN_LOGIN_POPUP
        });
    }

    handleCloseLoginPopup() {
        store.dispatch({
            type: CLOSE_LOGIN_POPUP
        });
    }

    handleGoBack() {
        const { account } = this.props;
        if (account && account.startUser && account.startUser.id) {
            /* const dialog = this.props.dialogs.items.filter( dialog => {
        return dialog.id == this.props.match.params.dialog_id
      }); */
            if (!sessionStorage.getItem("goBack")) {
                window.location = config.tap_tape_url2 + this.props.account.startUser.university.id;
            } else {
                const page = sessionStorage.getItem("goBack");
                sessionStorage.removeItem("goBack");
                window.location = page;
            }
        }
    }

    render() {
        if (this.props.dialogs.redirectDialogId && this.props.account.token) {
            return <Redirect to={"/dialogs/" + this.props.dialogs.redirectDialogId} />;
        } else if (!this.props.account.token) {
            if (!this.props.account.startUser) return null;

            const isOverlayVisible = this.props.registrationPopup.isOpened || this.props.loginPopup.isOpened || false;

            return (
                <div id="page-body" className="page-body js-index">
                    <ChatComponent
                        handleGoBack={this.handleGoBack}
                        handleLoginClick={this.handleLoginClick}
                        handleRegisterClick={this.handleRegisterClick}
                    />

                    <Modal
                        isOpen={this.props.registrationPopup.isOpened}
                        onClosed={this.handleCloseRegisterPopup}
                        hasOutsideClickClose={true}
                        hasEscapeClose={true}>
                        <RegisterComponent handleClosePopup={this.handleCloseRegisterPopup} />
                    </Modal>

                    <Modal
                        isOpen={this.props.loginPopup.isOpened}
                        onClosed={this.handleCloseLoginPopup}
                        hasOutsideClickClose={true}
                        hasEscapeClose={true}>
                        <LoginComponent handleClosePopup={this.handleCloseLoginPopup} />
                    </Modal>

                    <Overlay isVisible={isOverlayVisible} />
                </div>
            );
        } else return null;
    }
}

export default connect(state => {
    return {
        account: state.account,
        registrationPopup: state.registrationPopup,
        loginPopup: state.loginPopup,
        dialogs: state.dialogs
    };
})(withRouter(Register));
