import API from "../../../api";
import { call, put } from 'redux-saga/effects'
import {
    STUDENT_INFO_SUCCESS,
    STUDENT_INFO_FAILED
} from '../../actions/userActions';

export function* studentInfo(action) {
    const payload = action.payload;
    
    try {
        const response = yield call(() => {
            return API.users.userInfo(payload.student_id);
        });

        if(response.success) {

            yield put({
                type: STUDENT_INFO_SUCCESS, 
                payload: {
                    user: response.user
                }
            });
        } else {
            yield put({
                type: STUDENT_INFO_FAILED
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: STUDENT_INFO_FAILED
        });
    }
};