import mixpanel from 'mixpanel-browser';

let env_check = process.env.NODE_ENV === 'production';

if(env_check) {
    mixpanel.init('ea5481ab9a61dc827bb1f4a42d8a35fe');
} else {
    mixpanel.init('beffc15665059b5f97e204b75081afbb');
}

let actions = {
    track: (event, data) => {
        mixpanel.track(event, data);
    }
};

export let Mixpanel = actions;
