import { connect } from 'react-redux';
import React, {PureComponent} from 'react';

import store from '../../../../redux/store';
import {
    HIDE_NEW_MESSAGES,
    MESSAGES_INFO
} from '../../../../redux/actions/messagesActions';

var $ = require("jquery");

class NewMessagesPopup extends PureComponent {

    constructor(props) {
        super(props);

        this.handlePopupClick = this.handlePopupClick.bind(this);
    }

    handlePopupClick() {

        $(".chat-body").animate({
            scrollTop: document.getElementById("chat-body").scrollHeight
        }, 800);

        store.dispatch({
          type: MESSAGES_INFO,
          payload: {
            dialog_id: this.props.dialog_id
          }
        })

        store.dispatch({
            type: HIDE_NEW_MESSAGES
        })
    }

    render() {
        if(this.props.messages.isNewMessagesModalOpened) {
            return (
                <button type="button" className="new-messages-button" onClick={this.handlePopupClick} >
                    <span className="new-update-text">
                        New messages received
                    </span>
                </button>
            )
        } else {
            return null;
        }
    }
}

export default connect( state => {
    return {
        messages: state.messages
    }
})(NewMessagesPopup);
