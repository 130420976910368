import React, {PureComponent} from 'react';
import {reduxForm, Field} from 'redux-form';


class ResetForm extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            email: null,
            errors: {
                email: null
            }
        }

        this.setField = this.setField.bind(this)

    }

    setField(e) {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value })
    }

    render() {
        const { handleSubmit, onSignIn } = this.props;
        const errors = this.state.errors

        return(
            <div className="recovery-form">
                <div className="sign-in-title">Recover password</div>

                <label className="sign-up-form-label">
                    <input
                        name='email'
                        type='text'
                        placeholder='Email'
                        className="modal-input"
                        value={this.state.email}
                        onChange={this.setField}
                        aria-label="Enter your email address"
                        aria-required="true"
                    />
                    { errors.email ? <span className="error">{errors.email}</span> : null}
                </label>

                <p className="reset-link" onClick={onSignIn}>Sign in</p>

                <button onClick={() => {
                    handleSubmit(this.state.email);
                }} className="sign-up-sbm">Send</button>
            </div>
        )
    }
}

export default reduxForm({
    form: 'reset_password'
})(ResetForm);
