import {
    LOGIN_SUCCESS,
    ACCOUNT_INFO_SUCCESS,
    UNIVERSITY_QUESTIONS_SUCCESS,
    SAVE_UNIVERSITY_QUESTIONS_SUCCESS,
    GET_COUNTRIES_SUCCESS,
    GET_NATIONALITIES_SUCCESS,
    CHANGE_MAILING
} from "../../actions/accountActions";

import {
    STUDENT_INFO_SUCCESS
} from "../../actions/userActions";

import { ACCOUNT } from "../../store/initialState";

export default function(state = ACCOUNT, action) {
    const payload = action.payload;

    switch (action.type) {
    case LOGIN_SUCCESS: {
        return {
            ...state,
            token: payload.token
        };
    }

    case STUDENT_INFO_SUCCESS: {
        return {
            ...state,
            startUser: payload.user
        };
    }

    case ACCOUNT_INFO_SUCCESS: {
        return {
            ...state,
            account_info: payload.account,
            notificationData: payload.notificationData
        };
    }

    case UNIVERSITY_QUESTIONS_SUCCESS: {
        payload.quizQuestion.sort((a, b) => a.id - b.id);

        return {
            ...state,
            specializations: payload.specializations,
            tags: payload.tags,
            quizQuestion: payload.quizQuestion
        };
    }

    case SAVE_UNIVERSITY_QUESTIONS_SUCCESS: {
        return {
            ...state,
            showOptionalQuestions: false
        };
    }

    case GET_COUNTRIES_SUCCESS: {
        return {
            ...state,
            countries: payload.countries
        };
    }

    case GET_NATIONALITIES_SUCCESS: {
        return {
            ...state,
            nationalities: payload.nationalities
        };
    }

    case CHANGE_MAILING: {
        const account_info = state.account_info;
        account_info.mailing = payload.mailing;
        return {
            ...state,
            account_info
        };
    }
    default:
        return state;
    }
}
