import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import moment from "moment";
import Sprite from "../../../../assets/img/symbol/sprite.svg";
import { SET_FIRST_MESSAGE } from "../../../../redux/actions/accountActions";
import store from "../../../../redux/store";

class InteractionSite extends PureComponent {
    state = {
        message: null
    }

    handleKeyDown = ({ key }) => {
        if (key === 'Enter') this.startRegister()
    }

    changeInput = ({ target }) => {
        this.setState({ [ target.name ] : target.value})
    }

    startRegister = () => {
        store.dispatch({
            type: SET_FIRST_MESSAGE,
            payload: this.state.message
        })

        this.props.handleRegisterClick()
    }

    render() {
        const { handleRegisterClick, handleGoBack, handleLoginClick } = this.props;
        const tags = this.props.account.startUser.user_tags;
        const member = this.props.account.startUser;
        const online = member.last_online ? moment.duration(moment().diff(moment(member.last_online.updated_at))).asMinutes() : 10;

        return (<div className="chat">
            <div className="chat-block">
                <div className="chat-head">
                    <div className="container">
                        <div className="chat-head-left">
                            { <button type="button" onClick={handleGoBack} className="chat-back-btn">
                                <svg className="icon icon-back">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={ Sprite + "#back" } />
                                </svg>
                            </button> }

                            <div className={online < 5 ? "chat-name-img-container mod-online" : "chat-name-img-container"}>
                                <div className="chat-name-img-block">
                                    <img className="chat-name-img" src={this.props.account.startUser.avatar ? this.props.account.startUser.avatar.sizes["70x70"] || this.props.account.startUser.avatar.original : "https://www.chaarat.com/wp-content/uploads/2017/08/placeholder-user.png"}
                                        alt={this.props.account.startUser.name + "'s avatar'"} />
                                </div>
                            </div>
                            <div className="chat-name-block">
                                <h3 className="chat-name">{this.props.account.startUser.name}</h3>
                                <p className="chat-members">
                                    {tags.courses_types ? tags.courses_types[0].name + " " : ""}
                                    {tags.subject ? tags.subject[0].name + " " : ""}
                                    {tags.job_role ? tags.job_role[0].name + " " : ""}
                                    {tags.industry ? tags.industry[0].name : ""}
                                    {tags.staff ? tags.staff[0].name + " Staff" : ""}
                                </p>
                            </div>
                            <div className="student-tags-row">
                                { tags.interests ? tags.interests.map((interes, index) => {
                                    return <span key={index} className="student-tag">{interes.name}</span>;
                                }) : null}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chat-body">
                    <div className="container">
                        <div className="chat-body-list">
                            <div className="msg-item">
                                <div className="msgs-block">
                                    <div className="msg-block-item">
                                        <p className="msg-text">{this.props.account.startUser.introduction}</p>
                                        <div className="msg-controls">
                                            <p className="msg-time">16:34</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="get-start-block">
                                <p className="get-start-text">Create an account to start chatting with {this.props.account.startUser.name}<br />
                                and get a notification when they reply</p>
                                <button type="button" data-remodal-target="sign-up-2" className="get-start-btn" onClick={handleRegisterClick}>Get started</button>
                                <button type="button" className="sign-in-btn" onClick={handleLoginClick}>Sign in</button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="chat-footer">
                    <div className="container">
                        <div className="chat-form">
                            <input
                                type="text"
                                name="message"
                                value={this.state.message || ""}
                                onChange={this.changeInput}
                                onKeyDown={this.handleKeyDown}
                                className="chat-input"
                                placeholder='Write a message...'
                            />

                            <button type="submit" onClick={this.startRegister} className="chat-sbm">
                                <svg className="icon icon-send">
                                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={ Sprite + "#send" } />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}

export default connect(state => {
    return {
        account: state.account
    };
})(withRouter(InteractionSite));
