import { connect } from "react-redux";
import React, { PureComponent } from "react";
import { Switch, Button, Input } from "antd";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import ReactCodeInput from "react-verification-code-input";

import API from "../../../../api";

import store from "../../../../redux/store";
import {
    NOTIFICATION_METHOD_POPUP_STEP,
    CLOSE_NOTIFICATION_METHOD_POPUP,
    ACCOUNT_INFO
} from "../../../../redux/actions/accountActions";

const NOT_SUPPORTED_COUNTRIES = ["ca", "ky", "cl", "cn", "co", "cr", "hr", "cz", "cd", "cg", "do", "tl", "ec", "sv", "et", "gf", "gh", "gu", "gt", "hn", "hu", "id", "iq", "jp", "kz", "ke", "ki", "kg", "la", "my", "ml", "mx", "mc", "ma", "mz", "mm", "na", "np", "nz", "ni", "ng", "pk", "pw", "ps", "pa", "pe", "ph", "pr", "ro", "kn", "lc", "vc", "za", "kr", "lk", "tw", "tz", "tr", "us", "uy", "ve"];

class NotificationMethodPopup extends PureComponent {
    constructor(props) {
        super(props);
        this.textInput = React.createRef();
        this.state = {
            phone: "",
            dialCode: "",
            formattedPhone: "",
            vereficationCode: "",
            smsChecked: true,
            // whatsUpChecked: false,
            emailChacked: false,
            isValidField: false,
            isCountryNotSupported: false
        };
        this.handlePhoneInput = this.handlePhoneInput.bind(this);
        this.handleSwitchChange = this.handleSwitchChange.bind(this);
    }

    renderSwitchContainer() {
        return (
            <div>
                <div className="switch-container">
                    <div> SMS</div>
                    <div>
                        <Switch
                            checked={this.state.smsChecked}
                            onChange={(checked) => this.handleSwitchChange("sms")}
                            className="notification-switch"
                        />
                    </div>
                </div>
                {/* <div className="switch-container">
                    <div> WhatsApp</div>
                    <div>
                        <Switch
                            checked={this.state.whatsUpChecked}
                            onChange={(checked) => this.handleSwitchChange("whats_up")}
                            className="notification-switch"
                        />
                    </div>
                </div> */}
                <div className="switch-container">
                    <div> Email</div>
                    <div>
                        <Switch
                            checked={this.state.emailChacked}
                            onChange={(checked) => this.handleSwitchChange("email")}
                            className="notification-switch"
                        />
                    </div>
                </div>
            </div>
        );
    };

    renderPhoneInput() {
        return (
            <div className="notification-method-container">
                <div className="notification-method-name"> Mobile number</div>
                <div className="notification-input-container">
                    <PhoneInput
                        country={"gb"}
                        inputStyle={{ width: 180, height: 25, fontSize: 12 }}
                        dropdownClass="notification-input-dropdown"
                        value={this.state.phone}
                        inputProps={{
                            disabled: this.state.isCountryNotSupported
                        }}
                        onChange={(phone, country) => this.handlePhoneInput(phone, country.dialCode, country.countryCode)}
                    />
                    {
                        this.state.phone.length === 0 && !this.state.isCountryNotSupported
                            ? <span className="notification-warning">Please enter a mobile number</span>
                            : null
                    }
                    {
                        this.state.isCountryNotSupported
                            ? <span className="notification-warning">SMS notifications temporary are unavailable for your country</span>
                            : null
                    }
                </div>
            </div>
        );
    };

    renderEmailInput() {
        return (
            <div className="notification-method-container email-container">
                <p>Notifications will be sent to</p>
                <span className="notification-element"> {this.props.prospectEmail}</span>
            </div>
        );
    };

    renderNextStepButton() {
        return (
            <div className="button-container">
                <Button
                    size="small"
                    disabled={!this.state.isValidField}
                    className="popup-button"
                    onClick={() => this.getVerificationCode()}
                >
                    Next
                </Button>
            </div>
        );
    };

    handlePhoneInput(phone, dialCode, countryCode) {
        const isNotSupported = NOT_SUPPORTED_COUNTRIES.some(item => item === countryCode);
        const formattedPhone = phone.replace(/[-+() ]/g, "").slice(dialCode.length);
        this.setState({ phone, dialCode, formattedPhone, isValidField: formattedPhone.length > 7, isCountryNotSupported: isNotSupported });
    };

    handleSwitchChange(type) {
        if (type === "sms") {
            this.setState({
                smsChecked: true,
                // whatsUpChecked: false,
                emailChacked: false,
                isValidField: false
            });
        } else if (type === "email") {
            this.setState({
                smsChecked: false,
                // whatsUpChecked: false,
                emailChacked: true,
                isValidField: true,
                phone: "",
                isCountryNotSupported: false
            });
        }
    }

    getVerificationCode() {
        let notificationData;
        if (this.state.emailChacked) {
            notificationData = {
                method: "email",
                phone_number: "",
                phone_country: "",
                university_id: this.props.universityId
            };
        } else if (this.state.smsChecked) {
            notificationData = {
                method: "sms",
                phone_number: this.state.formattedPhone,
                phone_country: this.state.dialCode,
                university_id: this.props.universityId
            };
        }

        API.account.sendNotificationMethod(notificationData)
            .then((response) => {
                if (response.success) {
                    this.setState({
                        isValidField: false
                    });
                    if (notificationData.method !== "email") {
                        store.dispatch({
                            type: NOTIFICATION_METHOD_POPUP_STEP,
                            payload: 2
                        });
                    } else {
                        store.dispatch({
                            type: ACCOUNT_INFO
                        });
                        store.dispatch({
                            type: CLOSE_NOTIFICATION_METHOD_POPUP
                        });
                    }
                }
            });
        if (this.textInput.current) {
            this.textInput.current.__clearvalues__();
        }
    };

    handleBackClick() {
        this.setState({ isValidField: true });
        store.dispatch({
            type: NOTIFICATION_METHOD_POPUP_STEP,
            payload: 1
        });
    };

    handleVerificationCodeInput(vereficationCode) {
        this.setState({ vereficationCode, isValidField: vereficationCode.length === 5 });
    };

    sendVerificationCode() {
        API.account.sendVerificationCode({ verification_code: this.state.vereficationCode })
            .then((response) => {
                if (response.success) {
                    store.dispatch({
                        type: ACCOUNT_INFO
                    });
                    store.dispatch({
                        type: CLOSE_NOTIFICATION_METHOD_POPUP
                    });
                }
            });
    };

    render() {
        return (
            this.props.notificationMethod.step === 1
                ? <div>
                    <div className="notification-popup-header">
                        How would you like us to notify you when you recieve message?
                    </div>
                    {this.renderSwitchContainer()}
                    {this.state.smsChecked ? this.renderPhoneInput() : this.renderEmailInput()}
                    {this.renderNextStepButton()}
                </div>
                : <div>
                    <div className="notification-popup-title">
                        <div>
                            <button type="button" className="step-back-button" onClick={() => { this.handleBackClick(); }}>
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 24 24" width="25" height="25" preserveAspectRatio="xMinYMin" ><path d='M15 4l2 2l-6 6l6 6l-2 2l-8-8z' /></svg>
                            </button>
                        </div>
                        <div className="verification-title">
                            Enter Verification Code there
                        </div>
                    </div>
                    <div className="confirm-information">
                        Please confirm your account by entering the verification code sent to
                        {
                            this.state.smsChecked
                                ? <span> <span className="notification-element">{this.state.phone}</span>. It may take up to 1 minute.</span>
                                : <span className="notification-element"> {this.props.prospectEmail}</span>
                        }

                    </div>
                    <div className="verification-code-container">
                        {this.state.vereficationCode.length === 0 ? <span className="notification-warning">Please enter verification code</span> : null}
                        <ReactCodeInput
                            ref={this.textInput}
                            fields={5}
                            onChange={(code) => this.handleVerificationCodeInput(code)}
                            className="verification-code-field"
                            fieldWidth={40}
                            fieldHeight={40}
                        />
                        <button type="button" className="resend-code-button" onClick={() => this.getVerificationCode()}>
                            Resend code
                        </button>
                    </div>
                    <div className="button-container">
                        <Button
                            size="small"
                            disabled={!this.state.isValidField}
                            className="popup-button"
                            onClick={() => this.sendVerificationCode()}
                        >
                            Submit
                        </Button>
                    </div>
                </div>
        );
    }
}

export default connect(state => {
    return {
        notificationMethod: state.notificationMethodPopup
    };
})(NotificationMethodPopup);
