import API from "../../../api";
import { call, put } from 'redux-saga/effects';
import store from '../../store';
import {
    DIALOGS_INFO_SUCCESS,
    DIALOGS_INFO_FAILED,
    CLOSE_DIALOG_FAILED,
    CLOSE_DIALOG_SUCCESS,
    FLAG_DIALOG_FAILED,
    FLAG_DIALOG_SUCCESS,
    FIND_OR_CREATE_DIALOG_SUCCESS,
    FIND_OR_CREATE_DIALOG_FAILED,
    DIALOGS_INFO,
    AFTER_DIALOG_REPORT_FAILED,
    AFTER_DIALOG_REPORT_SUCCESS
} from '../../actions/dialogsActions';

import {
    OPEN_ACTION_MODAL
} from '../../actions/componentsActions';

export function* dialogsInfo(action) {
    
    try {
        const response = yield call(() => {
            return API.dialogs.get();
        });

        if(response.success) {

            yield put({
                type: DIALOGS_INFO_SUCCESS, 
                payload: {
                    items: response.data.dialogs
                }
            });
        } else {
            yield put({
                type: DIALOGS_INFO_FAILED
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: DIALOGS_INFO_FAILED
        });
    }
};

export function* flag(action) {
    const payload = action.payload;

    try {
        let data = {
            reported_reason: payload.reported_reason
        };
        const response = yield call(() => {
            return API.dialogs.flag(payload.dialog_id, data);
        });

        if(response.success) {

            yield put({
                type: FLAG_DIALOG_SUCCESS
            });

            yield put({
                type: DIALOGS_INFO
            });

            yield put({
                type: OPEN_ACTION_MODAL,
                payload: {
                    title: "Conversation status has been changed!"
                }
            })
        } else {
            yield put({
                type: FLAG_DIALOG_FAILED
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: FLAG_DIALOG_FAILED
        });
    }
}

export function* closeToggle(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.dialogs.closeToggle(payload.dialog_id);
        });

        if(response.success) {
            yield put({
                type: CLOSE_DIALOG_SUCCESS
            });

            yield put({
                type: DIALOGS_INFO
            });
            
        } else {
            yield put({
                type: CLOSE_DIALOG_FAILED
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: CLOSE_DIALOG_FAILED
        });
    }
}

export function* findOrCreateDialog(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.dialogs.findOrCreate(payload.student_id)
        })

        if(response.success) {

            yield put({
                type: FIND_OR_CREATE_DIALOG_SUCCESS,
                payload: {
                    dialog_id: response.data.dialog_id
                }   
            })
        }
        else
            yield put({
                type: FIND_OR_CREATE_DIALOG_FAILED
            })
    } catch(e) {
        console.log(e);

        yield put({
            type: FIND_OR_CREATE_DIALOG_FAILED
        })
    }
}

export function* afterDialogReport(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.dialogs.afterDialogReport(payload.dialog_id, {
                user_mark: payload.user_mark,
                university_mark: payload.university_mark
            })
        })

        if(response.success) {

            yield put({
                type: AFTER_DIALOG_REPORT_SUCCESS,
                payload: {
                    dialog_id: payload.dialog_id
                }   
            })
            
        }
        else
            yield put({
                type: AFTER_DIALOG_REPORT_FAILED
            })
    } catch(e) {
        console.log(e);

        yield put({
            type: AFTER_DIALOG_REPORT_FAILED
        })
    }
}
