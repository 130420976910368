import {
    MESSAGES_INFO_SUCCESS,
    NEW_MESSAGES_RECEIVED,
    HIDE_NEW_MESSAGES,
    CLEAR_MESSAGES_STORE
} from '../actions/messagesActions';
  
import { MESSAGES } from '../store/initialState';

export default function ( state = MESSAGES, action ) {
    const payload = action.payload;

    switch (action.type) {
        case MESSAGES_INFO_SUCCESS: {
            return {
                ...state,
                items: payload.items,
                univercity: payload.univercity,
                isLoading: false
            }
        }

        case NEW_MESSAGES_RECEIVED: {
            const newState = Object.assign({}, state, {
                isNewMessagesModalOpened: true
            });
    
            return newState;
          }
    
        case HIDE_NEW_MESSAGES: {
            const newState = Object.assign({}, state, {
                isNewMessagesModalOpened: false
            });

            return newState;
        }

        case CLEAR_MESSAGES_STORE: {
            const newState = MESSAGES;

            return newState;
        }

        default:
            return state
    }
}