import React, { PureComponent } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import Dropdown from "../../../components/Dropdown";

const TextQuestion = (props) => {
    return (
        <label className="sign-up-form-label">
            <p className="modal-label-text">{props.question}</p>
            <input
                name={props.name}
                type="text"
                className="modal-input"
                value={props.answer}
                onChange={(e) => props.onChange(props.name, props.type, e.target.value)}
                aria-label={props.question}
                aria-required="false"
            />
        </label>
    );
};

const BooleanQuestion = (props) => {
    return (
        <>
            <p className="modal-label-text">{props.question}</p>
            <div className="radio-row">
                <label className="radio-label">
                    <input
                        type="radio"
                        className="radio-input"
                        value="Yes"
                        name={props.name}
                        checked={props.answer === "Yes"}
                        onChange={() => props.onChange(props.name, props.type, "Yes")}
                        aria-label="Select an option"
                        aria-required="false"
                    />
                    <span className="radio-text">Yes</span>
                </label>
                <label className="radio-label">
                    <input
                        type="radio"
                        className="radio-input"
                        value="No"
                        name={props.name}
                        checked={props.answer === "No"}
                        onChange={() => props.onChange(props.name, props.type, "No")}
                        aria-label="Select an option"
                        aria-required="false"
                    />
                    <span className="radio-text">No</span>
                </label>
            </div>
        </>
    );
};

const DateQuestion = (props) => {
    return (
        <>
            <p className="modal-label-text">{props.question}</p>
            <div className="radio-row">
                <DatePicker
                    selected={props.answer}
                    onChange={(date) => props.onChange(props.name, props.type, date)}
                    className="ui-kit-input"
                    placeholderText="DD.MM.YYYY"
                    peekNextMonth
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    dateFormat="dd.MM.yyyy"
                />
            </div>
        </>
    );
};

const DropdownQuestion = (props) => {
    return (
        <>
            <p className="modal-label-text">{props.question}</p>
            <label className="label sign-up-form-label">
                <div className="control">
                    <Dropdown
                        items={props.options}
                        selectedItem={props.answer}
                        type={props.optionType}
                        onSelect={(value) => props.onChange(props.name, props.type, value)}
                    />
                </div>
            </label>
        </>
    );
};

const StartStudiesQuestion = (props) => {
    const options = props.options.map((tag) => (
        <label className="radio-label" key={tag.id}>
            <input
                type="radio"
                className="radio-input"
                value={tag.id}
                name={props.name}
                checked={props.answer === tag.id}
                onChange={() => props.onChange(props.name, props.type, tag.id)}
                aria-label="Select an option"
                aria-required="false"
            />
            <span className="radio-text">{tag.name}</span>
        </label>
    ));

    return (
        <>
            <p className="modal-label-text">{props.question}</p>
            <div className="modal-label">
                <div className="radio-row">
                    {options}
                </div>
            </div>
        </>
    );
};

class OptionalQuestions extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            answers: this.props.answers || {}
        };

        this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
    }

    handleFieldUpdate(questionId, questionType, answer) {
        const rawAnswer = { [questionId]: { answer, questionType } };
        const updatedAnswers = Object.assign({}, this.state.answers, rawAnswer);
        this.setState({ answers: updatedAnswers });

        let formattedAnswer = answer;
        switch (questionType) {
        case "text":
        case "boolean_toggle":
        case "start_studies":
            break;

        case "calendar":
            formattedAnswer = moment(answer).format("YYYY-MM-DD HH:mm:ss");
            break;

        case "countries":
        case "specializations":
            formattedAnswer = answer.id;
            break;
        }

        this.props.onFieldUpdate(questionId, formattedAnswer, rawAnswer);
    }

    renderQuestions() {
        if (!this.props.data || !this.props.data.length) {
            return null;
        }

        const questions = this.props.data.map((item) => {
            let question = null;
            switch (item.field_type) {
            case "text":
                question = (
                    <TextQuestion
                        key={item.key}
                        name={item.id}
                        type={item.field_type}
                        question={item.question}
                        answer={this.state.answers[item.id] ? this.state.answers[item.id].answer : ""}
                        onChange={this.handleFieldUpdate}
                    />
                );
                break;

            case "boolean_toggle":
                if (item.key === "applied" && !item.question.endsWith("?")) {
                    item.question += this.props.university + "?";
                }
                question = (
                    <BooleanQuestion
                        key={item.key}
                        name={item.id}
                        type={item.field_type}
                        question={item.question}
                        answer={this.state.answers[item.id] ? this.state.answers[item.id].answer : ""}
                        onChange={this.handleFieldUpdate}
                    />
                );
                break;

            case "calendar":
                question = (
                    <DateQuestion
                        key={item.key}
                        name={item.id}
                        type={item.field_type}
                        question={item.question}
                        answer={this.state.answers[item.id] ? this.state.answers[item.id].answer : ""}
                        onChange={this.handleFieldUpdate}
                    />
                );
                break;

            case "countries":
                question = (
                    <DropdownQuestion
                        key={item.key}
                        name={item.id}
                        type={item.field_type}
                        question={item.question}
                        answer={this.state.answers[item.id] ? this.state.answers[item.id].answer : { name: "" }}
                        options={this.props.countries}
                        optionType="country"
                        onChange={this.handleFieldUpdate}
                    />
                );
                break;

            case "specializations":
                question = (
                    <DropdownQuestion
                        key={item.key}
                        name={item.id}
                        type={item.field_type}
                        question={item.question}
                        answer={this.state.answers[item.id] ? this.state.answers[item.id].answer : { name: "" }}
                        options={this.props.specializations}
                        optionType="course"
                        onChange={this.handleFieldUpdate}
                    />
                );
                break;

            case "start_studies":
                question = (
                    <StartStudiesQuestion
                        key={item.key}
                        name={item.id}
                        type={item.field_type}
                        question={item.question}
                        answer={this.state.answers[item.id] ? this.state.answers[item.id].answer : ""}
                        options={this.props.startOptions}
                        onChange={this.handleFieldUpdate}
                    />
                );
                break;
            }

            return question;
        });

        return questions;
    }

    render() {
        return (
            <div className="optional-questions-form">
                {this.renderQuestions()}
            </div>
        );
    }
}

export default OptionalQuestions;
