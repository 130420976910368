import {
    OPEN_LOGIN_POPUP,
    CLOSE_LOGIN_POPUP
} from '../../actions/accountActions';
  
import { LOGIN_POPUP } from '../../store/initialState';

export default function ( state = LOGIN_POPUP, action ) {
    switch (action.type) {
        case OPEN_LOGIN_POPUP: {
            return {
                ...state,
                isOpened: true
            }
        }

        case CLOSE_LOGIN_POPUP: {
            return {
                ...state,
                isOpened: false
            }
        }

        default:
            return state
    }
}