import React, {PureComponent} from 'react';
import { connect } from 'react-redux';
import API from "../../../api";
import store from '../../../redux/store';
import $ from 'jquery'

import {
  ACCOUNT_INFO
} from '../../../redux/actions/accountActions';

import {
  OPEN_ACTION_MODAL_CHILD,
  OPEN_ACTION_MODAL,
  CLOSE_ACTION_MODAL_CHILD
} from '../../../redux/actions/componentsActions'

const renderField = ({input, label, placeholder, type, className, meta: {touched, error, warning}}) => {
return (
  <div>
    <input {...input} onChange={e => {
      input.onChange(e);
      $(".university-form .university-submit").removeClass("button-disabled");
    }} className={className} placeholder={placeholder} type={type}/>
    {touched && ((error && <span className="error">{error}</span>) || (warning && <span>{warning}</span>))}
  </div>
)};

class EditNameForm extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {
      error: null,
      startValue: null
    }
    this.changeName = this.changeName.bind(this)
    this.reloadAccount = this.reloadAccount.bind(this)
    this.changeEmail = this.changeEmail.bind(this)
    this.changePassword = this.changePassword.bind(this)
    this.changeLastName = this.changeLastName.bind(this)
  }

  componentWillMount () {
    const object = {}
    object[this.props.name] = this.props.value
    this.setState(object);
  }

  changeName () {
    const newName = this.state[this.props.name]
    if (!newName) return this.setState({error: 'Name must be filled'})
    API.account.changeName(newName).then(this.reloadAccount)
  }

  changeLastName () {
    const newName = this.state[this.props.name]
    if (!newName) return this.setState({error: 'Name must be filled'})
    API.account.changeLastName(newName).then(this.reloadAccount)
  }

  success() {
    store.dispatch({
      type: CLOSE_ACTION_MODAL_CHILD
    })
    store.dispatch({
      type: OPEN_ACTION_MODAL,
      payload: {
        title: 'Name successfully changed'
      }
    })
  }

  changeEmail () {
    const newEmail = this.state[this.props.name]
    if (!newEmail) return this.setState({error: 'Email must be filled'})
    API.account.changeEmail()

    const sendCode = () => {
      const value = $('#email-code').val()
      API.account.changeEmailConfirm(newEmail, value)
      .then(() => {
        this.reloadAccount()
      })
    }

    store.dispatch({
      type: OPEN_ACTION_MODAL_CHILD,
      payload: {
        children:  (
          <div className="general-popup" id="verification" >
              <h3 className="modal-title">Email verification</h3>
              <p className="subtitle">Please enter your code</p>
              <div className="wrap-input-number">
                <input id="email-code" type="text" />
              </div>
              <p className="subtitle text-footer-modal">A verification code has been sent to your email address. <br />Please enter the code here to continue.
              <br /><br />
              </p>
              <button  onClick={sendCode} className="el-btn">Submit</button>
          </div>
        )
      }
    })
  }

  changePassword () {
    const newPassword = this.state[this.props.name]
    if (!newPassword) return this.setState({error: 'Password must be filled'})
    API.account.changePassword()

    const sendCode = () => {
      const value = $('#password-code').val()
      API.account.changePasswordConfirm(newPassword, value)
      .then(() => {
        this.reloadAccount()
      })
    }

    store.dispatch({
      type: OPEN_ACTION_MODAL_CHILD,
      payload: {
        children:  (
          <div className="general-popup" id="verification" >
              <h3 className="modal-title">Email verification</h3>
              <p className="subtitle">Please enter your code</p>
              <div className="wrap-input-number">
                <input id="password-code" type="text" />
              </div>
              <p className="subtitle text-footer-modal">A verification code has been sent to your email address. <br />Please enter the code here to continue.
              <br /><br />
              </p>
              <button onClick={sendCode} className="el-btn">Submit</button>
          </div>
        )
      }
    })
  }

  reloadAccount() {
    this.props.callback()
    this.success()
    store.dispatch({
      type: ACCOUNT_INFO
    })
    this.setState({startValue: null})
  }


  render() {
    const { handleSubmit, placeholder, name, isVisible, type } = this.props;

    if (!isVisible) return ''

    if (!this.state.startValue) this.setState({startValue: this.state[name]})

    return (
        <div className="university-form university-input-row">
          <input
            name={name}
            component={renderField}
            type={ type ? type : 'text' }
            placeholder={placeholder}
            className='university-input'
            value={this.state[name]}
            onChange={(e) => {
              const object = this.state
              object[name] = e.currentTarget.value
              this.setState(object)
              this.forceUpdate()
            }}
          />
          <button disabled={this.state.startValue == this.state[name]} onClick={this[handleSubmit]} className="university-submit button-disabled">Save</button>

          <div className="error">{this.state.error}</div>
        </div>
    )
  }
}

export default connect(state => {
  return {
    account: state.account
  }
})(EditNameForm);
