import HttpService from '../../system/services/HttpService'

export default {
    get: () => {
        return HttpService.get('/interactionSite/dialogs')
    },
    flag: (dialog_id, data) => {
        return HttpService.put('/interactionSite/dialogs/' + dialog_id + '/report', data)
    },
    closeToggle: (dialog_id) => {
        return HttpService.put('/interactionSite/dialogs/' + dialog_id + '/archive/toggle')
    },
    findOrCreate: (student_id) => {
        return HttpService.post('/interactionSite/dialogs/' + student_id + '/findOrCreate')
    },
    afterDialogReport: (dialog_id, data) => {
        return HttpService.put('/interactionSite/dialogs/' + dialog_id + '/afteDialogReport', data)
    },
    sendFeedback: (date) => {
        return HttpService.put('/interactionSite/dialogs/sendFeedback', date)
    }
};
