import wretch from "wretch"
import qs from 'qs';
import store from "../../../redux/store"
import Notifications from "react-notification-system-redux";
import config from '../../config'

const defaults = {
    debug: true,
    api_host: config.api_host,
    headers: {
        "Accept": "application/json",
        "Content-Type": "text/plain"
    }
};

class HttpService {
    constructor() {
        let storeState = store.getState();

        this.instance = wretch()
            .defer((w, url, options) => {
                return w;
            })
            .defaults({ headers: defaults.headers })
            .options({ mode: "cors" })

        if (storeState.account && storeState.account.jwt) {
            this.instance = this.instance.options({
                headers: {
                    Authorization: 'Bearer ' + storeState.account.jwt
                }
            }, false)
        }

        this.instance = this.instance
            .catcher(400, err => {
                let json = JSON.parse(err.message);

                if (json && json.error && json.error.message) {
                    store.dispatch(
                        Notifications["error"]({
                            title: "Error",
                            message: json.error.message || "",
                            position: "tr",
                            action: ""
                        })
                    );
                } else {
                    store.dispatch(
                        Notifications["error"]({
                            title: "Error",
                            message: 'Something went wrong.' || "",
                            position: "tr",
                            action: ""
                        })
                    );
                }

                return json;
            })
            .catcher(401, err => {
                let json = JSON.parse(err.message);

                if (json && json.error && json.error.message) {
                    if (json.error.message.startsWith("E_INVALID_JWT_TOKEN")) {
                        localStorage.removeItem("TapAccountInteractionSite");
                        store.dispatch(
                            Notifications["error"]({
                                title: "Error",
                                message: "Your session has been expired. Please reload the page and login again.",
                                position: "tr",
                                action: ""
                            })
                        );
                    } else {
                        store.dispatch(
                            Notifications["error"]({
                                title: "Error",
                                message: json.error.message || "",
                                position: "tr",
                                action: ""
                            })
                        );
                    }
                } else {
                    store.dispatch(
                        Notifications["error"]({
                            title: "Error",
                            message: 'Something went wrong.' || "",
                            position: "tr",
                            action: ""
                        })
                    );
                }

                return json;
            })
            .catcher(403, err => {
                let json = JSON.parse(err.message);

                if (json && json.error && json.error.message) {
                    store.dispatch(
                        Notifications["error"]({
                            title: "Error",
                            message: json.error.message || "",
                            position: "tr",
                            action: ""
                        })
                    );
                } else {
                    store.dispatch(
                        Notifications["error"]({
                            title: "Error",
                            message: 'Something went wrong.' || "",
                            position: "tr",
                            action: ""
                        })
                    );
                }

                return json;
            })
            .catcher(404, err => {
                let json = JSON.parse(err.message);

                if (json && json.error && json.error.message) {
                    store.dispatch(
                        Notifications["error"]({
                            title: "Error",
                            message: json.error.message || "",
                            position: "tr",
                            action: ""
                        })
                    );
                } else {
                    store.dispatch(
                        Notifications["error"]({
                            title: "Error",
                            message: 'Something went wrong.' || "",
                            position: "tr",
                            action: ""
                        })
                    );
                }

                return json;
            })
            .catcher(500, err => {
                let json = JSON.parse(err.message);

                if (json && json.error && json.error.message) {
                    store.dispatch(
                        Notifications["error"]({
                            title: "Error",
                            message: json.error.message || "",
                            position: "tr",
                            action: ""
                        })
                    );
                } else {
                    store.dispatch(
                        Notifications["error"]({
                            title: "Error",
                            message: 'Something went wrong.' || "",
                            position: "tr",
                            action: ""
                        })
                    );
                }

                return json;
            });
    }

    static get(path, data) {
        return this
            .getInstance()
            .url(defaults.api_host + path)
            .query(qs.stringify(data))
            .get()
            .json();
    }

    static post(path, data) {
        return this
            .getInstance()
            .url(defaults.api_host + path)
            .json(data)
            .post()
            .json();
    }

    static put(path, data) {
        return this
            .getInstance()
            .url(defaults.api_host + path)
            .json(data)
            .put()
            .json();
    }

    static getInstance() {
        const instance = new this();
        let storeState = store.getState();

        if (storeState.account && storeState.account.token) {
            instance.instance = instance.instance.options({
                headers: {
                    Authorization: 'Bearer ' + storeState.account.token
                }
            }, false)
        }

        return instance.instance;
    }
}

export default HttpService;
