import React, { Component } from "react";
import store from "../redux/store";
import { withRouter } from "react-router-dom";
import { Modal, Overlay } from "react-modal-construction-kit";
import ActionPopup from "../components/popups/ActionPopup";
import ActionPopupChild from "../components/popups/ActionPopupChild";
import { connect } from "react-redux";
import "../assets/styles/libs/dropdown.min.css";
import "../assets/styles/libs/transition.min.css";
import "../assets/styles/custom/style.sass";
import "antd/dist/antd.css";
import API from "../api";

import {
    ACCOUNT_INFO,
    LOGIN_SUCCESS
} from "../redux/actions/accountActions";

import {
    CLOSE_ACTION_MODAL
} from "../redux/actions/componentsActions";

import Router from "./Router";
import auth from "../containers/register/components/auth";
import config from "../system/config";

class App extends Component {
    constructor() {
        super();
        this.state = {
            loading: true,
            loaded: false
        };

        this.authByCode = this.authByCode.bind(this);
    }

    componentWillMount() {
        // temporary redirect for users with wrong link
        const hash = window.location.hash.split("/interaction/dialogs/")[1];
        console.log(hash)
        if (hash && hash.length) {
            window.location = `${config.tap_tape_url2}${window.location.hash.split("#/")[1]}`
        } else {

            const authCode = window.location.href.split("?auth=")[1];

            if (this.props.account.token) {
                store.dispatch({
                    type: ACCOUNT_INFO
                });
                if (authCode) {
                    this.authByCode(authCode.split("&")[0]);
                }
            } else if (authCode) {
                this.authByCode(authCode);
            }
        }

        try {
            const backLink = window.location.href.split("back-to=")[1];
            if (backLink) {
                const paramsData = backLink.split("?")[1];

                sessionStorage.setItem("goBack", backLink);
                window.location = window.location.href.split("?back-to=")[0];
                if (paramsData) {
                    const utm_medium = paramsData.split("utm_medium=")[1];
                    const utm_source = paramsData.split("utm_source=")[1];
                    const utm_campaign = paramsData.split("utm_campaign=")[1];
                    const university_id = paramsData.split("university_id=")[1];
                    const cameFrom = paramsData.split("cameFrom=")[1];
                    if (university_id && (utm_medium || utm_source || utm_campaign || cameFrom)) {
                        let utmData = {};
                        const universityId = university_id.split("&")[0];
                        if (localStorage.getItem("utmData_" + universityId)) {
                            utmData = JSON.parse(localStorage.getItem("utmData_" + universityId));
                            utmData.utm_medium = utm_medium ? decodeURIComponent(utm_medium.split("&")[0]) : null;
                            utmData.utm_source = utm_source ? decodeURIComponent(utm_source.split("&")[0]) : null;
                            utmData.utm_campaign = utm_campaign ? decodeURIComponent(utm_campaign.split("&")[0]) : null;
                            utmData.cameFrom = utmData.cameFrom ? utmData.cameFrom : (utm_campaign ? decodeURIComponent(utm_campaign.split("&")[0]) : null);
                        } else {
                            utmData = {
                                utm_medium: utm_medium ? decodeURIComponent(utm_medium.split("&")[0]) : null,
                                utm_source: utm_source ? decodeURIComponent(utm_source.split("&")[0]) : null,
                                utm_campaign: utm_campaign ? decodeURIComponent(utm_campaign.split("&")[0]) : null,
                                university_id: universityId,
                                cameFrom: cameFrom ? decodeURIComponent(cameFrom.split("&")[0]) : null
                            };
                        }
                        localStorage.setItem("utmData_" + universityId, JSON.stringify(utmData));
                    }
                }
            }
        } catch (err) { console.log(err); }
    }

    authByCode(authCode) {
        API.account.authByCode(authCode).then((response) => {
            if (response.data) {
                store.dispatch({
                    type: LOGIN_SUCCESS,
                    payload: {
                        token: response.data.token
                    }
                });

                localStorage.setItem("TapAccountInteractionSite", JSON.stringify(
                    response.data.token
                ));

                store.dispatch({
                    type: ACCOUNT_INFO
                });
            }

            window.location.hash = window.location.hash.split("?auth=")[0];
        });
    }

    handleClosePopup() {
        store.dispatch({
            type: CLOSE_ACTION_MODAL
        });
    }

    componentDidMount() {
        window.addEventListener("load", () => {
            this.setState({ loading: false });
            setTimeout(() => this.setState({ loaded: true }), 500);
        });
    }

    render() {
        const loaded = this.state.loaded;
        return (
            <div>
                {!loaded && <div className={`load${this.state.loading ? "" : " loaded"}`}>
                    <div className='load__icon-wrap'>
                        <svg className='load__icon' viewBox="0 0 350 340">

                            <defs>
                                <clipPath id="clipPath111">
                                    <path d="m25.3379 73.248h14.1099v-47.3507h287.616v288.205h-287.616v-34.464h-14.1099v48.5828h315.835v-316.443h-315.835z"/>
                                </clipPath>
                            </defs>

                            <g className="logotype logo-animation">
                                <g className="frame logo-animation" clipPath="url(#clipPath111)">
                                    <path className="frame_line logo-animation" d="m32.3693 73.3333v-54.4936h301.732v302.418l-301.732-1.1428v-40.4586" fill="none" stroke="#f14f1b" strokeWidth="14"/>
                                </g>
                                <path className="t_letter logo-animation" d="m9.54203 96.8173v-10.7467h45.665v10.7467h-16.702v41.1973h-12.3251v-41.1973h-16.638" fill="#262b3f"/>
                                <path className="h_letter letters logo-animation" d="m71.9447 102.409c.779067-.74667 1.6572-1.38401 2.60987-1.89334 1.08067-.58266 2.224-1.04266 3.40787-1.368 3.44053-.866659 7.0624-.67733 10.3937.54267 1.58853.66933 2.964 1.76 3.97867 3.15467 1.06507 1.524 1.7968 3.256 2.14733 5.08266.462 2.29067.680667 4.624.652667 6.96v23.1267h-11.8355v-20.1907c0-1.38666 0-2.71866-.099466-3.90533-.044267-1.09467-.276534-2.17467-.689067-3.19067-.3532-.87467-.962533-1.624-1.7484-2.14667-1.01093-.58266-2.17093-.85599-3.33547-.78799-2.53693 0-4.37707.84399-5.5188 2.53599-1.14213 1.69201-1.7156 4.33201-1.72133 7.92134v19.764h-11.7812v-51.944h11.7812v18.496c.487467-.79333 1.07867-1.51867 1.75787-2.15733" fill="#262b3f"/>
                                <path className="e1_letter letters logo-animation" d="m114.156 109.08c-1.7448 1.32-2.8292 3.33467-2.9724 5.51867h16.7015c.06413-2.09067-.83427-4.09467-2.43747-5.43734-1.62493-1.23466-3.6244-1.87466-5.664-1.81333-2.01093-.02666-3.9792.57867-5.6276 1.732zm21.976 22.7267c-1.32813 1.57333-2.886 2.936-4.62187 4.04266-1.67706 1.06934-3.51146 1.86667-5.43746 2.36534-1.88027.5-3.81774.756-5.7636.76133-2.9292.036-5.84267-.44-8.6088-1.40533-2.47347-.86534-4.74067-2.232-6.66094-4.01467-1.9-1.79333-3.3828-3.984-4.34013-6.416-1.06453-2.74-1.58747-5.66133-1.5412-8.6-.036933-2.86666.524533-5.70933 1.64947-8.34533 1.03133-2.396 2.5428-4.55467 4.44013-6.344 1.8984-1.76934 4.12867-3.14667 6.56147-4.05067 2.55266-.968 5.26253-1.45733 7.99266-1.44133 2.95734-.048 5.8896.538659 8.60054 1.72133 2.4776 1.10667 4.67186 2.76134 6.41573 4.83867 1.7952 2.17867 3.11613 4.70666 3.87853 7.424.8548 3.076 1.17974 6.27467.96094 9.46h-28.6187c.0468 1.25867.35573 2.492.90627 3.624.49373.96 1.18906 1.80133 2.03906 2.46533.81667.64267 1.73587 1.14133 2.71814 1.47733 1.01773.336 2.0828.504 3.15373.49867 1.8984.07067 3.78693-.29867 5.51867-1.07733 1.30266-.60267 2.39893-1.57333 3.15373-2.792h10.8749c-.75106 2.108-1.85773 4.07333-3.27133 5.808" fill="#262b3f"/>
                                <path className="a1_letter letters logo-animation" d="m41.7311 182.556l-6.6692-19.7827-6.73387 19.7827zm.145333-32.76l19.638 51.9723h-13.3307l-3.15373-9.24427h-20.0729l-3.08067 9.24427h-13.0495l19.5557-51.9723h13.4937" fill="#262b3f"/>
                                <path className="c1_letter letters logo-animation" d="m86.7071 162.8c2.02667.49733 3.9464 1.36 5.66413 2.54667 1.81147 1.26 3.36773 2.85333 4.58493 4.69333 1.42293 2.20267 2.38693 4.668 2.83693 7.24933h-11.038c-.296934-1.86666-1.28333-3.55466-2.76413-4.72933-1.36147-.924-2.97653-1.40133-4.62187-1.368-1.05413.00267-2.0984.19867-3.08067.58-1.04747.4-1.98133 1.05067-2.7188 1.89467-.858266 1.00933-1.5052 2.17866-1.90307 3.44266-.513067 1.68134-.7552 3.43467-.716134 5.192-.0412 1.79734.200534 3.58934.716134 5.312.387466 1.26667 1.03587 2.44 1.90307 3.44267.747467.82533 1.67813 1.46 2.7188 1.85733.9828.37734 2.02707.572 3.08067.57067 1.88853.09733 3.75107-.47067 5.26573-1.60267 1.33587-1.112 2.25827-2.64133 2.61813-4.34133h11.038c-.545732 2.58533-1.60573 5.036-3.1172 7.204-2.53853 3.59453-6.29733 6.1424-10.5755 7.168-1.9448.4864-3.94013.74-5.9448.75307-2.87707.04946-5.73747-.44787-8.42813-1.4692-2.35-.90787-4.46773-2.3276-6.19787-4.1584-1.7084-1.8588-3.00213-4.05747-3.798-6.45347-.890533-2.68933-1.32853-5.512-1.2952-8.34667-.0396-2.83733.4484-5.65866 1.44053-8.31866.893733-2.37334 2.27347-4.53334 4.05107-6.34267 1.76827-1.78533 3.89227-3.17733 6.23493-4.088 2.54947-.97733 5.26147-1.464 7.99267-1.43067 2.04067.00267 4.07293.25334 6.0536.74267" fill="#262b3f"/>
                                <path className="c2_letter letters logo-animation" d="m128.284 162.8c2.0244.49733 3.94 1.36 5.65467 2.54667 1.81507 1.26 3.37387 2.85333 4.5948 4.69333 1.4192 2.20267 2.38067 4.66933 2.8276 7.24933h-11.038c-.52613-2.24666-1.4412-3.824-2.76413-4.72933-1.362-.924-2.97654-1.40133-4.62187-1.368-1.05413.004-2.0984.2-3.0812.58-1.04747.39867-1.98133 1.04933-2.71827 1.89467-.86146 1.00666-1.5088 2.17733-1.90306 3.44266-.5172 1.68-.75894 3.43467-.71627 5.192-.0452 1.79734.1964 3.59067.71627 5.312.38373 1.26934 1.03226 2.44267 1.90306 3.44267.7464.82533 1.6776 1.46133 2.71827 1.85733.98387.376 2.02813.57067 3.0812.57067 1.8912.09867 3.75787-.46933 5.274-1.60267 1.3344-1.112 2.25413-2.64266 2.6104-4.34133h11.0468c-.54893 2.58533-1.61187 5.036-3.12653 7.204-1.3 1.83613-2.92814 3.4148-4.80307 4.6576-1.76307 1.16093-3.71307 2.0104-5.76307 2.5104-1.94786.4864-3.9468.73853-5.95466.75307-2.87347.0484-5.73027-.44894-8.4188-1.4692-2.352-.90267-4.47134-2.324-6.1984-4.1584-1.7136-1.85627-3.01094-4.05614-3.80574-6.45347-.888-2.69067-1.32293-5.512-1.2864-8.34667-.03853-2.83733.4468-5.65733 1.4312-8.31866.90054-2.36934 2.27974-4.52934 4.05107-6.34267 1.7692-1.784 3.89373-3.176 6.2344-4.088 2.55-.97733 5.2624-1.46267 7.99267-1.43067 2.04373.00267 4.07973.252 6.06306.74267" fill="#262b3f"/>
                                <path className="e2_letter letters logo-animation" d="m159.105 172.795c-1.74933 1.324-2.83866 3.34-2.98266 5.52933h16.7027c.06-2.09067-.83733-4.092-2.43733-5.43733-1.62667-1.23334-3.62533-1.872-5.664-1.81334-2.00667-.02666-3.972.57467-5.61867 1.72134zm21.9667 22.7283c-1.33067 1.57294-2.888 2.93854-4.62133 4.05054-1.68134 1.0624-3.51467 1.86146-5.43867 2.36666-1.884.4932-3.824.7452-5.772.752-2.92667.0396-5.83733-.43333-8.6-1.39626-2.47333-.86934-4.744-2.2344-6.66933-4.01307-1.89734-1.79893-3.37867-3.98813-4.34134-6.41747-1.06266-2.74133-1.58666-5.66-1.54-8.6-.03733-2.868.524-5.71466 1.64934-8.356 1.03466-2.39466 2.548-4.55333 4.44933-6.34266 1.9-1.76267 4.12533-3.14 6.552-4.05067 2.55467-.96133 5.264-1.44667 7.992-1.432 2.96133-.05067 5.89733.536 8.60933 1.72267 2.47867 1.104 4.67334 2.75866 6.416 4.83866 1.79467 2.17334 3.112 4.69734 3.86934 7.41334.85733 3.07866 1.18666 6.28133.96933 9.46933h-28.6173c.05333 1.24267.36133 2.46133.90666 3.58.49067.96533 1.19067 1.81067 2.04667 2.47333.81867.63867 1.73733 1.13467 2.71867 1.468 1.01866.336 2.08266.504 3.15466.49867 1.89734.07333 3.78534-.29333 5.51867-1.06933 1.308-.596 2.40667-1.572 3.15333-2.80134h10.8747c-.748 2.124-1.85867 4.10134-3.28 5.8456" fill="#262b3f"/>
                                <path className="s1_letter letters logo-animation" d="m210.388 171.545c-1.564-.556-3.216-.82266-4.876-.788-4.11333 0-6.16133.95067-6.16133 2.864-.03067.86134.388 1.676 1.10533 2.156 1.112.66267 2.344 1.1 3.62533 1.28667l7.168 1.28667c4.62667.864 8.06667 2.108 10.3213 3.73333 2.25334 1.62533 3.37734 4.13333 3.37067 7.52267.04 1.98933-.44533 3.956-1.40533 5.69853-.94534 1.664-2.28534 3.06827-3.904 4.088-1.81867 1.13747-3.81734 1.96147-5.90934 2.43747-2.432.56933-4.92399.84533-7.42133.8256-2.37866.01973-4.75066-.25787-7.06-.8256-2.044-.4932-3.98533-1.34267-5.736-2.50934-1.63466-1.1052-3.01466-2.54533-4.05066-4.224-1.088-1.80933-1.796-3.824-2.076-5.91733h10.9653c.35467 1.64267 1.41467 3.04667 2.9 3.83333 1.63067.74267 3.41067 1.09867 5.20133 1.04267 1.75867.06 3.51333-.23333 5.156-.86133 1.16133-.33067 1.976-1.376 2.012-2.58267-.024-.904-.53333-1.724-1.332-2.148-1.46133-.76533-3.05333-1.25067-4.69333-1.432l-6.87867-1.15067c-3.30266-.444-6.42933-1.75466-9.06266-3.79733-2.34667-1.98667-3.632-4.95733-3.47067-8.028-.032-1.79733.452-3.56533 1.396-5.09333.97467-1.51867 2.288-2.79334 3.83333-3.72534 1.744-1.05466 3.64267-1.828 5.628-2.292 2.22-.536 4.496-.80133 6.77867-.78933 2.132-.00667 4.26.22133 6.34266.68 1.948.42 3.81334 1.15733 5.51867 2.184 1.628.98933 3.04133 2.29333 4.16 3.83333 1.16667 1.68267 1.93733 3.60534 2.25733 5.628h-11.056c-.32133-1.36666-1.32-2.476-2.64666-2.936" fill="#262b3f"/>
                                <path className="s2_letter letters logo-animation" d="m250.669 171.545c-1.564-.556-3.216-.82266-4.87467-.788-4.11466 0-6.16266.95067-6.16266 2.864-.02667.86.392 1.67334 1.10533 2.156 1.112.66267 2.344 1.1 3.62533 1.28667l7.168 1.28667c4.63334.864 8.07334 2.108 10.3213 3.73333 2.24666 1.62533 3.37066 4.13333 3.37066 7.52267.04 1.98933-.444 3.956-1.404 5.69853-.94266 1.6656-2.284 3.0708-3.90533 4.088-1.82 1.13747-3.81733 1.96147-5.90933 2.43747-2.432.56933-4.92267.84533-7.42134.8256-2.37866.01973-4.75066-.25787-7.06-.8256-2.044-.4932-3.98666-1.34267-5.736-2.50934-1.63466-1.1052-3.01466-2.54533-4.05066-4.224-1.08934-1.80933-1.796-3.824-2.076-5.91733h10.9653c.35467 1.64267 1.41467 3.04667 2.9 3.83333 1.63067.74267 3.41067 1.09867 5.20133 1.04267 1.75867.06 3.51334-.23333 5.156-.86133 1.16134-.33067 1.976-1.376 2.01334-2.58267-.02667-.904-.53334-1.724-1.33334-2.148-1.46133-.76533-3.05333-1.25067-4.69466-1.432l-6.87734-1.15067c-3.30266-.444-6.42933-1.75466-9.06266-3.79733-2.35067-1.984-3.636-4.95733-3.46934-8.028-.03466-1.79733.45067-3.56533 1.39467-5.09333.976-1.51867 2.288-2.79334 3.83333-3.72534 1.744-1.05466 3.64267-1.828 5.62667-2.292 2.22133-.536 4.49733-.80133 6.77867-.78933 2.13333-.00533 4.26133.22267 6.344.68 1.948.42 3.81333 1.15733 5.52 2.184 1.628.98533 3.03866 2.28933 4.14933 3.83333 1.172 1.68 1.94667 3.604 2.26533 5.628h-11.056c-.32-1.36666-1.31866-2.476-2.64533-2.936" fill="#262b3f"/>
                                <path className="p_letter letters logo-animation" d="m26.18 237.165h8.09333c1.3432.0084 2.6848-.0864 4.01453-.2812 1.138-.16254 2.24067-.5156 3.262-1.0428.917733-.4676 1.6916-1.1744 2.23853-2.04787.568267-1.02653.8416-2.18907.788533-3.36253.0468-1.15054-.226-2.2932-.788533-3.29894-.5344-.86506-1.29173-1.57293-2.1932-2.04786-1.01773-.52654-2.12347-.86414-3.26253-.99587-1.3308-.1776-2.67187-.26147-4.01453-.25307h-8.13813zm17.0923-22.9369c2.6416.3932 5.18533 1.28387 7.49373 2.62814 2.0896 1.25053 3.79947 3.04533 4.94787 5.1932 2.38133 5.36253 2.38133 11.4823 0 16.8464-1.14733 2.14946-2.85773 3.9468-4.94787 5.20146-2.31147 1.34374-4.85267 2.2464-7.49373 2.66414-3.09013.52133-6.21987.77186-9.35267.752h-7.7396v17.9969h-12.2968v-51.9984h20.0364c3.13187-.0172 6.25987.22133 9.35267.71613" fill="#262b3f"/>
                                <path className="l_letter letters logo-animation" d="m60.5081 265.484v-51.9719h11.7808v51.9719h-11.7808" fill="#262b3f"/>
                                <path className="a2_letter letters logo-animation" d="m97.5993 248.991c-1.47293.19213-2.92347.52906-4.3312 1.00666-1.0496.3412-2.00787.91614-2.80053 1.68387-1.398 1.4828-1.33027 3.8172.151467 5.21467.131333.1228.271333.23746.419333.34 1.13587.79066 2.49587 1.1948 3.87867 1.15213 1.11147.0104 2.21867-.136 3.29-.43493.9772-.276 1.874-.77707 2.61934-1.46774.79786-.77853 1.4-1.73586 1.75733-2.79213.4656-1.4224.68333-2.91613.6432-4.412v-.9068c-2.0536.16933-3.92973.37347-5.6276.61627zm3.69733-13.9011c-1.4176-1.00573-3.14053-1.4896-4.87493-1.36827-1.6844-.07346-3.34173.436-4.6948 1.44014-1.27347 1.052-2.06507 2.576-2.19267 4.22386h-11.0376c.148533-1.82386.685467-3.59573 1.57667-5.1932.954133-1.70253 2.24627-3.19213 3.7968-4.3776 1.76253-1.3292 3.7396-2.34626 5.84533-3.00773 2.4932-.77347 5.0932-1.14533 7.70307-1.1068 2.4912-.02653 4.97453.27707 7.38547.9068 2.0052.51867 3.86453 1.48907 5.43746 2.83693 1.52814 1.35467 2.67707 3.08547 3.33494 5.02027.79266 2.4 1.14533 4.924 1.04213 7.448l-.1452 8.39213v4.01454c0 1.43186 0 2.8376.1088 4.224.10827 1.38546.16307 2.7188.29013 3.91359.088 1.02494.24214 2.04214.46254 3.04627h-11.6l-.1448-4.65986c-1.33707 1.97599-3.25214 3.48906-5.48294 4.33333-2.402.86666-4.94107 1.2932-7.49467 1.25986-1.75267.00947-3.49533-.2588-5.1652-.78906-1.5448-.49054-2.98853-1.2588-4.25933-2.2656-2.538-1.98387-3.98693-5.05307-3.9052-8.27347-.065067-2.14266.602533-4.24266 1.89373-5.95466 1.42293-1.74374 3.2448-3.11974 5.3104-4.01454 2.60573-1.15053 5.34587-1.96933 8.15573-2.43693 3.41987-.6032 6.86773-1.0308 10.3308-1.28707v-1.5052c-.01773-2.3-.5772-3.90773-1.67667-4.81973" fill="#262b3f"/>
                                <path className="tf_letter letters logo-animation" d="m160.138 223.263c.12533-.41306.408-.76093.788-.96933.492-.2412 1.028-.37707 1.57733-.3984.66933-.04533 1.53067-.07187 2.58267-.07187h3.37066v-8.31093h-6.02666c-2.20267-.024-4.40534.11867-6.588.42653-1.58934.20414-3.11067.76827-4.44934 1.64787-1.15733.82973-2.03333 1.99373-2.51066 3.33653-.58267 1.7916-.84934 3.67027-.788 5.55414v2.43746h-13.6841v-10.5385h-11.8355v10.5385h-6.09v8.39227h6.09v19.3479c-.05 1.84946.16986 3.69586.6532 5.4828.34266 1.29426 1.0848 2.44786 2.12026 3.29839 1.15987.8548 2.51414 1.40947 3.94214 1.6136 1.9896.3172 4.00266.46254 6.01666.4344h7.53134v-8.53599h-3.37187c-1.9568 0-3.298-.27187-4.01413-.82654-.71614-.55213-1.06974-1.7292-1.06974-3.54266v-17.2719h13.6837v30.1771h11.836v-30.1771h8.52666v-8.39227h-8.52666v-1.90253c-.01067-.59227.068-1.18133.236-1.74947" fill="#262b3f"/>
                                <path className="o_letter letters logo-animation" d="m186.192 235.515c-1.056.38707-1.99333 1.04427-2.71867 1.90267-.84133 1.00413-1.46 2.17493-1.812 3.4348-.85866 3.40267-.85866 6.9652 0 10.3677.34134 1.2656.93867 2.44694 1.75734 3.47094.704.85986 1.616 1.52653 2.64666 1.93853 2.14934.81093 4.52.81093 6.66934 0 1.03466-.40947 1.94933-1.0772 2.65466-1.93853.81467-1.0256 1.41467-2.2068 1.75867-3.47094.85733-3.40253.85733-6.96506 0-10.3677-.36-1.2572-.97733-2.42653-1.812-3.4348-.72667-.85733-1.664-1.51467-2.71867-1.90267-2.10933-.76146-4.416-.76146-6.52533 0zm10.9733-8.52653c2.4.78027 4.61867 2.03493 6.52534 3.688 1.96266 1.75053 3.51066 3.9188 4.53066 6.34267 2.244 5.83333 2.244 12.2917 0 18.1251-1.03066 2.4188-2.57733 4.5828-4.53066 6.34427-1.9 1.6708-4.12 2.93746-6.52534 3.724-5.00533 1.62906-10.3987 1.62906-15.4053 0-2.40667-.7828-4.62667-2.04947-6.524-3.724-1.95333-1.76147-3.49867-3.92547-4.532-6.34427-2.24133-5.83333-2.24133-12.2917 0-18.1251 1.02-2.42387 2.568-4.59214 4.532-6.34267 1.904-1.65733 4.12267-2.912 6.524-3.688 5.00667-1.6292 10.4-1.6292 15.4053 0" fill="#262b3f"/>
                                <path className="r_letter letters logo-animation" d="m236.55 238.063h-1.50533c-2.872 0-5.028.59734-6.488 1.81187-1.45867 1.2136-2.184 3.29907-2.184 6.3428v19.3563h-11.836v-38.6589h11.4013v7.31467c.788-2.1224 2.12267-3.99853 3.87067-5.43853 1.928-1.4256 4.27067-2.18294 6.66933-2.15734h.86c.304.0016.60667.02654.90667.07347v11.5359c-.644-.1-1.21467-.15467-1.69467-.18014" fill="#262b3f"/>
                                <path className="m_letter letters logo-animation" d="m241.852 265.484v-38.5693h11.8267v5.0932c.48134-.7864 1.06134-1.5104 1.72134-2.1572.764-.75213 1.63466-1.39066 2.58133-1.89426 1.068-.5776 2.196-1.0376 3.36267-1.36667 1.30666-.36307 2.65866-.5428 4.016-.53493 2.564-.12814 5.11733.44266 7.38533 1.64946 1.85067 1.1412 3.28533 2.85214 4.08667 4.87454 1.42133-2.1 3.37466-3.7828 5.664-4.87454 2.35066-1.11093 4.92266-1.67493 7.52133-1.64946 2.12133-.06667 4.23333.3 6.208 1.07706 1.588.66987 2.964 1.7604 3.97733 3.15467 1.05734 1.52813 1.78934 3.25893 2.148 5.0844.45467 2.29067.67067 4.624.644 6.95987v23.1532h-11.808v-20.2172c0-1.38654-.04533-2.7188-.14533-3.9068-.064-1.0948-.30667-2.17187-.716-3.18907-.35733-.8776-.96933-1.624-1.75733-2.14787-1.012-.5828-2.17067-.85786-3.336-.7896-2.43734 0-4.18667.83334-5.264 2.5016-1.07867 1.6676-1.61334 4.23174-1.61334 7.67547v20.0735h-11.844v-20.2172c0-1.38654 0-2.7188-.14533-3.9068-.06933-1.0948-.31067-2.17027-.71733-3.18907-.356-.8776-.96934-1.624-1.75734-2.14787-1.01066-.5828-2.16933-.85786-3.33466-.7896-2.43734 0-4.18667.83334-5.26534 2.5016-1.07866 1.6676-1.612 4.23174-1.612 7.67547v20.0735h-11.8267" fill="#262b3f"/>
                            </g>

                        </svg>
                    </div>
                </div>}
                <div>
                    <Router/>
                </div>

                <Modal
                    isOpen={this.props.actionPopup.isOpened}
                    onClosed={this.handleClosePopup}
                    hasOutsideClickClose={true}
                    hasEscapeClose={true}>
                    <ActionPopup title={this.props.actionPopup.title} body={this.props.actionPopup.body} children={this.props.actionPopup.children} handleClose={this.handleClosePopup} />
                </Modal>

                <Modal
                    isOpen={this.props.actionPopup.withChild.isOpened}
                    onClosed={this.handleClosePopup}
                    hasOutsideClickClose={true}
                    hasEscapeClose={true}>
                    <ActionPopupChild children={this.props.actionPopup.withChild.children} handleClose={this.handleClosePopup} />
                </Modal>

                <Overlay isVisible={this.props.actionPopup.isOpened || this.props.actionPopup.withChild.isOpened} />
            </div>
        );
    }
}

export default connect(state => {
    return {
        actionPopup: state.actionPopup,
        account: state.account
    };
})(withRouter(App));
