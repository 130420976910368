import React from 'react';
import {Route, Switch, HashRouter, Redirect} from 'react-router-dom';
import MainWrapper from './MainWrapper';
import Dialogs from '../containers/dialogs';
import Messaging from '../containers/messaging';
import NotificationsWrapper from '../components/NotificationsWrapper';
import IsAuthorized from '../components/IsAuthorized';
import RedirectIfGuest from '../components/RedirectIfGuest';
import Settings from '../containers/settings'
import SignIn from '../containers/signIn'
import Register from '../containers/register'

const unAuthorizedRoutes = () => {
  return (
    <Switch>
      <RedirectIfGuest>
        <SignIn />
      </RedirectIfGuest>
    </Switch>
  );
}

const authorizedRoutes = () => {
  return (
    <Switch>
      <IsAuthorized>
        <Route exact path='/dialogs' component={Dialogs}/>
        <Route exact path='/dialogs/:dialog_id(\d+)' component={Messaging}/>
        <Route exact path='/interaction-site/:student_id(\d+)' component={Register} />
        <Route exact path='/settings' component={Settings} />
        <Route exact path='/' component={Dialogs} />
      </IsAuthorized>
    </Switch>  
  );
}


const Router = () => (
  <MainWrapper>
    <main>
      <HashRouter>
        <Switch>
          <NotificationsWrapper>
            <Route path='/' component={unAuthorizedRoutes}/>
            <Route path='/' component={authorizedRoutes}/>
          </NotificationsWrapper>
        </Switch>
      </HashRouter>
    </main>
  </MainWrapper>
);

export default Router;
