import React from 'react'

export default ({ dialog, children, handleSurePopupClick }) => {

    if (dialog.admin_archived) {


        return (
            <div className="chat-footer mod-closed">
                <p className="closed-text">Admin closed this conversation</p>
            </div>
        )


    }else if (dialog.archived) {


        return (
            <div className="chat-footer mod-closed" onClick={() => {handleSurePopupClick('handleReopenDialog')}}>
                <p className="closed-text">This conversation is closed</p>
                <button type="button" className="reopen-btn">Reopen</button>
            </div>
        )


    } else if (dialog.user_reported || dialog.hide_keywords || dialog.reported) {


        return (
            <div className="chat-footer">
                <p className="chat-footer-flagged">This conversation has been flagged</p>
            </div>
        )


    } else {
        return (
            <div className="chat-footer">
                { children }
            </div>
        )
    }
}