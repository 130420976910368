import React from 'react';
import { connect } from 'react-redux';
import {Redirect, withRouter} from 'react-router-dom';

const IsAuthorized = (props) => {
    if(window.location.href.lastIndexOf('/interaction-site/') != -1 || (props.account.account_info && props.account.account_info.id)) {
        return (
            <div>{props.children}</div>
        );
    } else {
        return null;
    }
};
  
export default connect(state => {
    return {
        account: state.account
    };
})(withRouter(IsAuthorized));
