import API from "../../../api";
import { call, put } from 'redux-saga/effects'
import {
    MESSAGES_INFO_FAILED,
    MESSAGES_INFO_SUCCESS,
    SEND_MESSAGE_FAILED,
    SEND_MESSAGE_SUCCESS,
    MESSAGES_INFO
} from '../../actions/messagesActions';

export function* messagesInfo(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.messages.get(payload.dialog_id);
        });

        if(response.success) {
            yield put({
                type: MESSAGES_INFO_SUCCESS, 
                payload: {
                    items: response.data.messages,
                    univercity: response.data.univercity
                }
            });

            if (payload.func) {
                payload.func(response.data)
            }
        } else {
            yield put({
                type: MESSAGES_INFO_FAILED
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: MESSAGES_INFO_FAILED
        });
    }
};

export function* send(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.messages.send(payload.type, payload.text, payload.dialog_id);
        });

        if(response.success) {

            yield put({
                type: SEND_MESSAGE_SUCCESS
            });

            yield put({
                type: MESSAGES_INFO,
                payload: {
                    dialog_id: payload.dialog_id
                }
            })
        } else {
            yield put({
                type: SEND_MESSAGE_FAILED
            });
        }
    } catch (e) {
        console.log(e);
        yield put({
            type: SEND_MESSAGE_FAILED
        });
    }
}