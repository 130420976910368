import React, {PureComponent} from 'react';
import {reduxForm} from 'redux-form';


class ResetForm extends PureComponent {

    constructor(props) {
        super(props);

        this.state = {
            new_password: null,
            confirm_password: null,
            errors: {
                new_password: null,
                confirm_password: null
            }
        }

        this.setField   = this.setField.bind(this)
        this.send       = this.send.bind(this)

    }

    setField(e) {

        this.setState({ [e.currentTarget.name]: e.currentTarget.value, error: {
            ...this.state.errors,
            [e.currentTarget.name]: null
        } })
    }

    send(new_password, confirm_password) {
        this.setState({
            errors: {}
        })
        if (new_password !== confirm_password) {
            return this.setState({
                errors: {
                    ...this.state.errors,
                    confirm_password: 'Passwords do not match'
                }
            })
        }

        this.props.handleSubmit(new_password, confirm_password)
    }

    render() {
        const { onSignIn } = this.props;
        const errors = this.state.errors

        return(
            <div className="recovery-form">
                <div className="sign-in-title">Recover password</div>

                <label className="sign-up-form-label">
                    <input
                        name='new_password'
                        type='password'
                        placeholder='New password'
                        className="modal-input"
                        value={this.state.new_password}
                        onChange={this.setField}
                        aria-label="Select a password"
                        aria-required="true"
                    />
                    { errors.new_password ? <span className="error">{errors.new_password}</span> : null}
                </label>

                <label className="sign-up-form-label">
                    <input
                        name='confirm_password'
                        type='password'
                        placeholder='Confirm password'
                        className="modal-input"
                        value={this.state.confirm_password}
                        onChange={this.setField}
                        aria-label="Confirm your password"
                        aria-required="true"
                    />
                    { errors.confirm_password ? <span className="error">{errors.confirm_password}</span> : null}
                </label>

                <p className="reset-link" onClick={onSignIn}>Sign in</p>

                <button onClick={() => {
                    this.send(this.state.new_password, this.state.confirm_password);
                }} className="sign-up-sbm">Send</button>
            </div>
        )
    }
}

export default reduxForm({
    form: 'reset_password'
})(ResetForm);
