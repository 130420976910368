import {
    OPEN_REGISTRATION_POPUP,
    CLOSE_REGISTRATION_POPUP,
    NEXT_REGISTRATION_STEP,
    SET_FIRST_MESSAGE,
    REMOVE_FIRST_MESSAGE
} from "../../actions/accountActions";

import {
    SET_DATA_REGISTER_MODAL
} from "../../actions/componentsActions";

import { REGISTER_POPUP } from "../../store/initialState";

export default function(state = REGISTER_POPUP, action) {
    const payload = action.payload;
    switch (action.type) {
    case OPEN_REGISTRATION_POPUP: {
        return {
            ...state,
            isOpened: true
        };
    }

    case CLOSE_REGISTRATION_POPUP: {
        return {
            ...state,
            isOpened: false
        };
    }

    case NEXT_REGISTRATION_STEP: {
        return {
            ...state,
            step: payload.step
        };
    }

    case SET_DATA_REGISTER_MODAL: {
        const data = state.data;

        data[payload.step ? payload.step : 1][payload.key] = payload.val;

        return {
            ...state,
            data
        };
    }

    case SET_FIRST_MESSAGE: {
        return {
            ...state,
            firstMessage: payload
        };
    }

    case REMOVE_FIRST_MESSAGE: {
        return {
            ...state,
            firstMessage: null
        };
    }

    default:
        return state;
    }
}
