import React, {PureComponent} from 'react';
import store from '../../../../redux/store';
import {connect} from 'react-redux';
import LoginForm from './LoginForm';
import {withRouter} from 'react-router-dom';
import ResetForm from './ResetPassword';
import API from "../../../../api";
import ResetFormFinish from './ResetFormFinish';

import {
  OPEN_LOGIN_POPUP,
  LOGIN
} from '../../../../redux/actions/accountActions';

import {
  OPEN_ACTION_MODAL_CHILD,
  CLOSE_ACTION_MODAL_CHILD
} from '../../../../redux/actions/componentsActions';

class LoginPopup extends PureComponent {

  constructor(props) {
    super(props);

    this.state = {
      step: 'auth'
    }

    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.startRecovery      = this.startRecovery.bind(this)    
    this.endRecovery        = this.endRecovery.bind(this)
    this.handleClosePopup   = this.handleClosePopup.bind(this)
  }

  handleRegisterClick() {
    store.dispatch({
      type: OPEN_LOGIN_POPUP
    })
  }

  handleLoginSubmit(values) {
      store.dispatch({
          type: LOGIN,
          payload: {
            ...values,
            student_id: this.props.match.params.student_id
          }
      })
  }

  startRecovery(email) {
    this.setState({ emailToReset: email })
    API.account.startRecovery(email).then(() => {

      store.dispatch({
        type: OPEN_ACTION_MODAL_CHILD,
        payload: {
          children:  (
            <div className="general-popup" id="verification" >
                <h2 className="modal-title">Email sent</h2>
                <div className="sub-title">Please check your email inbox. If you cannot find an email from us within the next couple of minutes, please check your spam/junk folders.</div>

                <button type="submit" className="el-btn general-action-button cancel" onClick={this.handleClosePopup}>Cancel</button>
            </div>
          )
        }
      })

      this.setState({ step: 'auth' })

    })
  }

  handleClosePopup () {
    store.dispatch({
      type: CLOSE_ACTION_MODAL_CHILD
    })
  }

  endRecovery(new_password, confirm_password) {

    API.account.recovery(this.state.userIdToReset, this.state.hexToReset, new_password, confirm_password).then((response) => {
      store.dispatch({
        type: LOGIN_SUCCESS, 
        payload: {
            token: response.data.token
        }
      })
  
      localStorage.setItem('TapAccountInteractionSite', JSON.stringify(
        response.data.token
      ));
  
      store.dispatch({
        type: ACCOUNT_INFO
      })

    })
  }

  render() {

    return(
        <div className="sign-up-1">
          { this.state.step == 'auth' ?
            <LoginForm onSubmit={this.handleLoginSubmit} onReset={() => this.setState({ step: 'recovery' })} />
          : null }

          { this.state.step == 'recovery' ? 
            <ResetForm handleSubmit={this.startRecovery} onSignIn={() => this.setState({ step: 'auth' })}/> 
          : null }

          { this.state.step == 'recovery-confirm' ? 
            <ResetFormFinish handleSubmit={this.endRecovery} onSignIn={() => this.setState({ step: 'auth' })}/> 
          : null }
        </div>
    )
  }
}

export default connect( state => {
  return {
    loginPopup: state.loginPopup
  }
})(withRouter(LoginPopup));
