import React, {PureComponent} from 'react';
import store from '../../redux/store';
import {connect} from 'react-redux';
import {withRouter, NavLink} from 'react-router-dom';
var moment = require("moment");

import {
  DIALOGS_INFO
} from '../../redux/actions/dialogsActions';

class Dialogs extends PureComponent {

  componentDidMount() {
    store.dispatch({
      type: DIALOGS_INFO
    })
  }

  render() {

    if(this.props.dialogs.isLoading) return null;

    const dialogsView = this.props.dialogs.items.map( dialog => {
        const { last_message, first_ambassador } = dialog
        const created_at = last_message ? moment(last_message.created_at).calendar(null, {
            sameDay: 'H:mm',
            nextDay: '[Tomorrow]',
            nextWeek: '[Next] dddd',
            lastDay: '[Yesterday at ]H:mm',
            lastWeek: 'DD MMMM',
            sameElse: 'DD MMMM'
        }) : null;

        const online = dialog.last_activity ? moment.duration(moment().diff(moment(dialog.last_activity))).asMinutes() : 10;

        let text = last_message.content && last_message.content.text && last_message.content.text.replace(/\[\/?badword\]/g, "") || '';
        text = text && text.replace(/\[\/?badword\]/g, "");

        return  <NavLink key={dialog.id} className="chat-list-nav-link" to={"/dialogs/" + dialog.id}>
                  <div className={dialog.archived ? "chat-list-item mod-closed" : "chat-list-item"} onClick={this.handleDialogClick}>
                      <div className={online < 5 ? "chat-name-img-container mod-chat-list mod-online" : "chat-name-img-container mod-chat-list"}>
                          <div className={`chat-name-img-block ${!dialog.avatar && 'chat-name-img-block--border'}`}>
                            {
                              dialog.avatar
                                ? <img className="chat-name-img" src={dialog.avatar.sizes["270x270"] || dialog.avatar.original} alt={dialog.members[0].name + "'s avatar"}/>
                                : <div className="chat-name-img--placeholder">{dialog.name.slice(0,1).toUpperCase()}</div>
                            }
                          </div>
                      </div>
                    {
                      dialog.name &&
                      <div className="chat-list-text-block">
                          <p className="chat-list-block-name">{dialog.name}</p>
                          <p className="chat-list-block-text">{text}</p>
                      </div>
                    }
                      <div className="chat-list-info-block">
                          <p className="chat-list-time">{created_at}</p>
                          <button type="button" className="go-to-chat">
                          <svg className="icon icon-back">
                              <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="assets/img/symbol/sprite.svg#back"></use>
                          </svg>
                          </button>
                      </div>
                  </div>
                </NavLink>
    })

    return(
      <div id="page-body" className="page-body js-index">
        <div className="chat">
          <div className="chat-block">
            <div className="chat-head">
              <div className="chat-head-left">
                <h1 className="chat-list-page">My chats</h1>
              </div>
              <div className="chat-head-right">
                <button type="button" className="chat-control">
                  <svg className="icon icon-menu">
                    <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="assets/img/symbol/sprite.svg#menu"></use>
                  </svg>
                </button>
              </div>
            </div>
            <div className="chat-list">
              {dialogsView}
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default connect( state => {
  return {
    account: state.account,
    dialogs: state.dialogs
  }
})(withRouter(Dialogs));
