import { takeEvery } from "redux-saga/effects";

import {
    STUDENT_INFO
} from "../actions/userActions";

import {
    LOGIN,
    REGISTRATION,
    ACCOUNT_INFO,
    UNIVERSITY_QUESTIONS,
    SAVE_UNIVERSITY_QUESTIONS,
    GET_COUNTRIES,
    GET_NATIONALITIES,
    ACCOUNT_CHANGE_AVATAR
} from "../actions/accountActions";

import {
    DIALOGS_INFO,
    FLAG_DIALOG,
    CLOSE_DIALOG,
    FIND_OR_CREATE_DIALOG,
    AFTER_DIALOG_REPORT
} from "../actions/dialogsActions";

import {
    MESSAGES_INFO,
    SEND_MESSAGE
} from "../actions/messagesActions";

import {
    studentInfo
} from "./studentsSaga";

import {
    dialogsInfo,
    flag,
    closeToggle,
    findOrCreateDialog,
    afterDialogReport
} from "./dialogsSaga";

import {
    login,
    registration,
    accountInfo,
    universityQuestions,
    saveUniversityQuestions,
    getCountries,
    getNationalities,
    accountChangeAvatar
} from "./accountSaga";

import {
    messagesInfo,
    send
} from "./messagesSaga";

function * saga() {
    yield takeEvery(STUDENT_INFO, studentInfo);
    yield takeEvery(DIALOGS_INFO, dialogsInfo);
    yield takeEvery(LOGIN, login);
    yield takeEvery(ACCOUNT_INFO, accountInfo);
    yield takeEvery(MESSAGES_INFO, messagesInfo);
    yield takeEvery(SEND_MESSAGE, send);
    yield takeEvery(FLAG_DIALOG, flag);
    yield takeEvery(CLOSE_DIALOG, closeToggle);
    yield takeEvery(UNIVERSITY_QUESTIONS, universityQuestions);
    yield takeEvery(SAVE_UNIVERSITY_QUESTIONS, saveUniversityQuestions);
    yield takeEvery(REGISTRATION, registration);
    yield takeEvery(FIND_OR_CREATE_DIALOG, findOrCreateDialog);
    yield takeEvery(AFTER_DIALOG_REPORT, afterDialogReport);
    yield takeEvery(GET_COUNTRIES, getCountries);
    yield takeEvery(GET_NATIONALITIES, getNationalities);
    yield takeEvery(ACCOUNT_CHANGE_AVATAR, accountChangeAvatar);
};

export default saga;
