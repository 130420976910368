import React, {PureComponent} from 'react';
import {connect} from 'react-redux';
import {withRouter, Redirect} from 'react-router-dom';
import Api from '../../api'
import config from '../../system/config';

class Register extends PureComponent {

    constructor(props) {
        super(props);
    }

    async componentDidMount() {
        const rate = window.location.hash.split('rate=')[1]
        const code = window.location.hash.split('feedback/')[1].split('?')[0]
        
        try {
            const result = await Api.dialogs.sendFeedback({ rate, code })

            this.univercityId = result.data.univercityId

        } catch(e) {
            
        }
        
    }

    handleGo() {

        window.location = config.tap_tape_url2  + this.univercityId
    
        return;
      }

    render() {
        return(
            <div className="feedback-body">
                <div className="feedback-block">
                    <strong>Thanks</strong> for your feedback!
                    <br/>
                    <button onClick={() => { this.handleGo() }}>Chat </button> 
                    <p> to another ambassador</p>
                </div>
            </div>
        )
    }
}

export default connect( state => {
  return {
    account: state.account,
    registrationPopup: state.registrationPopup,
    loginPopup: state.loginPopup,
    dialogs: state.dialogs
  }
})(withRouter(Register));
