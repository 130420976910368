import React, { PureComponent } from "react";

import OptionalQuestions from "./../OptionalQuestions";

class OptionalDataPopup extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            answers: {},
            privacy: false,
            errors: {},
            areQuestionsLoaded: false
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.validation = this.validation.bind(this);
        this.setFieldCheckbox = this.setFieldCheckbox.bind(this);
        this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
        this.renderTitle = this.renderTitle.bind(this);
    }

    static getDerivedStateFromProps(props, state) {
        if (props.university && !state.areQuestionsLoaded) {
            state.areQuestionsLoaded = true;

            props.loadOptionalQuestions(props.university.id);

            return state;
        }

        return null;
    }

    handleSubmit() {
        if (!this.validation()) {
            return;
        }

        const data = [];
        for (const questionId in this.state.answers) {
            if (this.state.answers[questionId] !== "") {
                data.push({
                    question_id: questionId,
                    answer: this.state.answers[questionId]
                });
            }
        }

        data.push({
            question_id: null,
            answer: "Accepted Privacy Policy"
        });

        this.props.onSubmit(data);
    }

    validation() {
        const errors = {};

        if (!this.state.privacy) {
            errors.privacy = "Field required";
        }

        if (Object.keys(errors).length) {
            this.setState({ errors });

            return false;
        }

        this.setState({ errors });

        return true;
    }

    setFieldCheckbox(e) {
        this.setState({ privacy: e.currentTarget.checked }, () => { this.validation(); });
    }

    handleFieldUpdate(questionId, answer) {
        const updatedAnswers = Object.assign({}, this.state.answers, { [questionId]: answer });
        this.setState({ answers: updatedAnswers });
    }

    renderTitle() {
        return (
            <>
                <h2 className="modal-name">
                    Before you start chatting to {this.props.ambassador.name}
                </h2>
                <span className="modal-subtext">
                    Please answer these optional questions and accept {this.props.university.name} privacy policy. Fields marked with <span style={{ color: "red" }}>*</span> are required.
                </span>
            </>
        );
    }

    render() {
        if (!this.props.ambassador) {
            return null;
        }

        const university = this.props.university;

        let privacyLink = university.privacy_policy;
        if (privacyLink && !privacyLink.indexOf("http") > -1) {
            privacyLink = "http://" + privacyLink;
        }

        return (
            <div className="remodal sign-up-1" id="sign-up-1" data-remodal-id="sign-up-1">
                {this.renderTitle()}
                <OptionalQuestions
                    data={this.props.questions}
                    university={university.name}
                    countries={this.props.countries}
                    specializations={this.props.specializations}
                    startOptions={this.props.startOptions}
                    onFieldUpdate={this.handleFieldUpdate}
                />
                <div className="sign-up-form">
                    <label className="check-label">
                        <input
                            type="checkbox"
                            className="checkbox"
                            name="privacy"
                            value={this.state.privacy}
                            onChange={this.setFieldCheckbox}
                            aria-label="Please agree to the terms"
                            aria-required="true"
                        />
                        <span className="checkbox-icon" />
                        <p className="checkbox-text">
                            <span style={{ color: "red" }}>* </span>I am over the age of 13 and consent to my personal data being processed by {university.name} according to their <a href={privacyLink} target="_blank">Privacy Policy</a> and being shared with The Access Platform according to their <a href="https://tap.st/privacy" target="_blank">Privacy Notice</a> and <a href="https://tap.st/user-terms" target="_blank">terms</a> so that they can facilitate my messaging interactions with {university.name}
                        </p>
                        {this.state.errors.privacy && <span className="error">{this.state.errors.privacy}</span>}
                    </label>

                    <button onClick={this.handleSubmit} className="sign-up-sbm">Start conversation</button>
                </div>
            </div>
        );
    }
}

export default OptionalDataPopup;
