import React, { PureComponent } from "react";
import { connect } from "react-redux";

import store from "../../../../redux/store";

import {
    SET_DATA_REGISTER_MODAL
} from "../../../../redux/actions/componentsActions";

import OptionalQuestions from "./../../../messaging/components/OptionalQuestions";

class Step2 extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            answers: this.props.data ? this.props.data.answers : {},
            rawAnswers: this.props.data ? this.props.data.rawAnswers : {},
            communications: null
        };

        this.submit = this.submit.bind(this);
        this.handleFieldUpdate = this.handleFieldUpdate.bind(this);
        this.setField = this.setField.bind(this);
    }

    componentDidMount() {
        const data = this.props.registrationPopup.data[2];
        Object.keys(data).map(key => {
            this.setState({ [key]: data[key] });
        });
    }

    submit() {
        const data = [];
        for (const questionId in this.state.answers) {
            if (this.state.answers[questionId] !== "") {
                data.push({
                    question_id: questionId,
                    answer: this.state.answers[questionId]
                });
            }
        }

        data.push({
            question_id: null,
            answer: "Accepted Privacy Policy"
        });

        this.props.onSubmit({ data, communications: this.state.communications });
    }

    handleFieldUpdate(questionId, answer, rawAnswer) {
        const updatedAnswers = Object.assign({}, this.state.answers, { [questionId]: answer });
        const updatedRawAnswers = Object.assign({}, this.state.rawAnswers, rawAnswer);
        this.setState({ answers: updatedAnswers, rawAnswers: updatedRawAnswers });
    }

    setField(e) {
        this.setState({ [e.currentTarget.name]: e.currentTarget.value });
        store.dispatch({
            type: SET_DATA_REGISTER_MODAL,
            payload: {
                key: e.currentTarget.name,
                val: e.currentTarget.value,
                step: 2
            }
        });
    }

    render() {
        return (
            <div className="sign-up-form">
                <OptionalQuestions
                    data={this.props.questions}
                    university={this.props.university}
                    countries={this.props.account.countries}
                    specializations={this.props.account.specializations}
                    startOptions={this.props.account.tags}
                    onFieldUpdate={this.handleFieldUpdate}
                    answers={this.state.rawAnswers}
                />

                <div className="modal-label">
                    <div>
                        <p className="modal-label-text">Do you want to receive email marketing communications?</p>
                        <div className="radio-row">
                            <label className="radio-label">
                                <input
                                    type="radio"
                                    className="radio-input"
                                    value="Yes"
                                    name="communications"
                                    checked={this.state.communications === "Yes"}
                                    onChange={this.setField}
                                    aria-label="Select an option"
                                    aria-required="false"
                                />
                                <span className="radio-text">Yes</span>
                            </label>
                            <label className="radio-label">
                                <input
                                    type="radio"
                                    className="radio-input"
                                    value="No"
                                    name="communications"
                                    checked={this.state.communications === "No"}
                                    onChange={this.setField}
                                    aria-label="Select an option"
                                    aria-required="false"
                                />
                                <span className="radio-text">No</span>
                            </label>
                        </div>
                    </div>

                </div>
                <div className='sign-up-buttons'>
                    <button
                        onClick={() => this.props.onBackClick({ answers: this.state.answers, rawAnswers: this.state.rawAnswers })}
                        className="sign-up-sbm"
                    >
                        Back
                    </button>
                    <button onClick={this.submit} className="sign-up-sbm">Sign up</button>
                </div>
            </div>
        );
    }
}

export default connect(state => {
    return {
        registrationPopup: state.registrationPopup
    };
})(Step2);
