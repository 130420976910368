import React, { PureComponent } from "react";
import store from "../../../../redux/store";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { checkEmail } from "../../../../redux/sagas/accountSaga";
import { Mixpanel } from "../../../../system/services/MixpanelService";

import {
    UNIVERSITY_QUESTIONS,
    SAVE_UNIVERSITY_QUESTIONS,
    REGISTRATION,
    // GET_COUNTRIES,
    GET_NATIONALITIES
} from "../../../../redux/actions/accountActions";

import Step1 from "./step1";
import Step2 from "./step2";

class RegistrationPopup extends PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            emailReceive: false,
            settings: {
                1: {
                    title: "Get started",
                    text: "Enter your details to create an account and start chatting with",
                    avatar: true
                },
                2: {
                    title: "Final step!",
                    text: "Please answer these optional questions"
                }
            },
            dataFirstStep: null,
            step: 1,
            emailUniqueError: false,
            secondStepData: {}
        };

        this.handleChangeEmailReceive = this.handleChangeEmailReceive.bind(this);
        this.handleRegistrationSubmit = this.handleRegistrationSubmit.bind(this);
        this.handleNextStepClick = this.handleNextStepClick.bind(this);
        this.handleBackStepClick = this.handleBackStepClick.bind(this);
        this.renderTitle = this.renderTitle.bind(this);
    }

    componentDidMount() {
        store.dispatch({
            type: UNIVERSITY_QUESTIONS,
            payload: this.props.account.startUser.university.id
        });

        store.dispatch({
            type: GET_NATIONALITIES
        });
    }

    handleRegistrationSubmit(values, answers) {
        let utmData = {};
        const universityId = this.props.account.startUser.university.id;
        if (localStorage.getItem("utmData_" + universityId)) {
            utmData = JSON.parse(localStorage.getItem("utmData_" + universityId));
        }

        if (!utmData.length) {
            utmData.university_id = universityId;
        }

        const onSuccess = () => {
            Mixpanel.track("Second step", {
                Prospect: this.state.dataFirstStep.first_name
            });
        };

        const onLoginSuccess = () => {
            store.dispatch({
                type: SAVE_UNIVERSITY_QUESTIONS,
                payload: {
                    universityId,
                    answers
                }
            });
        };

        store.dispatch({
            type: REGISTRATION,
            payload: {
                ...values,
                ...utmData,
                student_id: this.props.match.params.student_id
            },
            onSuccess,
            onLoginSuccess
        });
    }

    handleChangeEmailReceive(event) {
        this.setState({
            emailReceive: event.target.getAttribute("data-value")
        });
    }

    handleNextStepClick(values) {
        if (this.state.step === 1) {
            checkEmail(values.email)
                .then(result => {
                    if (result.success) {
                        this.setState({ dataFirstStep: values, step: 2, emailUniqueError: false });
                        Mixpanel.track("First step", {
                            Prospect: values.first_name
                        });
                    } else { this.setState({ emailUniqueError: true }); };
                });
        } else {
            const registrationData = { communications: values.communications, ...this.state.dataFirstStep };

            this.handleRegistrationSubmit(registrationData, values.data);
        }
    }

    handleBackStepClick(secondStepData) {
        this.setState((state) => ({
            ...state,
            step: 1,
            secondStepData
        }));
    }

    renderTitle(data) {
        return (
            <div>
                <h2 className="modal-name">{data.title}</h2>
                <p className="modal-subtext">{data.text}</p>
                { data.avatar ? <div className="ambassador-profile">
                    <span><div className="modal-subtext-img-span"><img className="modal-subtext-img" src={this.props.account.startUser.avatar ? this.props.account.startUser.avatar.sizes["70x70"] || this.props.account.startUser.avatar.original : "https://www.chaarat.com/wp-content/uploads/2017/08/placeholder-user.png"} alt={this.props.account.startUser.name + "'s avatar"} /></div></span> {this.props.account.startUser.name}
                </div> : null }
            </div>
        );
    }

    render() {
        const dataSettings = this.state.settings[this.state.step];

        let privacyLink = this.props.account.startUser.university.privacy_policy;
        if (privacyLink && !privacyLink.indexOf("http") > -1) {
            privacyLink = "http://" + privacyLink;
        }

        const step = this.state.step === 1
            ? <Step1
                privacyLink={privacyLink}
                nationalities={this.props.account.nationalities}
                emailError={this.state.emailUniqueError}
                onSubmit={this.handleNextStepClick}
                university={this.props.account.startUser.university}
            />
            : <Step2
                questions={this.props.account.quizQuestion}
                university={this.props.account.startUser.university.name}
                account={this.props.account}
                onSubmit={this.handleNextStepClick}
                onBackClick={this.handleBackStepClick}
                data={this.state.secondStepData}
            />;

        return (
            <div className="remodal sign-up-1" id="sign-up-1" data-remodal-id="sign-up-1">
                {this.renderTitle(dataSettings)}
                {step}
            </div>
        );
    }
}

export default connect(state => {
    return {
        account: state.account,
        registrationPopup: state.registrationPopup
    };
})(withRouter(RegistrationPopup));
