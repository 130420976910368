import saga from '../sagas';
import createSagaMiddleware from 'redux-saga';
import { combineReducers, createStore, applyMiddleware } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { composeWithDevTools } from 'redux-devtools-extension';
import { reducer as notifications } from 'react-notification-system-redux';
import {
  sidebarReducer,
  themeReducer,
  accountReducer,
  registrationPopupReducer,
  notificationMethodReducer,
  dialogsReducer,
  loginPopupReducer,
  messagesReducer,
  actionPopupReducer
} from '../reducers/index';

const sagaMiddleware = createSagaMiddleware();

const reducer = combineReducers({
  form: reduxFormReducer, // mounted under "form",
  theme: themeReducer,
  notifications: notifications,
  sidebar: sidebarReducer,
  account: accountReducer,
  registrationPopup: registrationPopupReducer,
  notificationMethodPopup: notificationMethodReducer,
  dialogs: dialogsReducer,
  loginPopup: loginPopupReducer,
  messages: messagesReducer,
  actionPopup: actionPopupReducer
});

const store = createStore(reducer,
  composeWithDevTools(
    applyMiddleware(sagaMiddleware)
  )
);

sagaMiddleware.run(saga);

export default store;