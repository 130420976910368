import React from 'react';
import { connect } from 'react-redux';
import Feedback from '../containers/feedback'

const RedirectIfGuest = (props) => {
    
    if ( window.location.href.includes('/feedback') ) {

        return <Feedback />

    } else if(window.location.href.lastIndexOf('/interaction-site/') == -1 && !props.account.token) {
        return (
            <div>{props.children}</div>
        );
    } else {
        return null;
    }
};
  
export default connect(state => {
    return {
        account: state.account
    };
})(RedirectIfGuest);
