import {
    DIALOGS_INFO_SUCCESS,
    DIALOGS_INFO,
    FIND_OR_CREATE_DIALOG_SUCCESS
} from '../actions/dialogsActions';
  
import { DIALOGS } from '../store/initialState';

export default function ( state = DIALOGS, action ) {
    const payload = action.payload;

    switch (action.type) {
        case DIALOGS_INFO_SUCCESS: {
            return {
                ...state,
                items: payload.items,
                isLoading: false
            }
        }

        /*case DIALOGS_INFO: {
            return {
                ...state,
                isLoading: true
            }
        }*/

        case FIND_OR_CREATE_DIALOG_SUCCESS: {
            return {
                ...state,
                redirectDialogId: payload.dialog_id
            }
        }

        default:
            return state
    }
}