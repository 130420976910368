const config = {}
if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {

    /* DEVELOPMENT */

    config.api_host_clear = 'https://api-dev.tap.tf/'
    config.api_host = config.api_host_clear + 'v1'
    config.carusel_url = 'https://tap-dev-widget.s3.amazonaws.com/index.html#/'
    config.tap_page_url = 'https://tap-dev-carousel-widget.s3.amazonaws.com/index.html#/'
    config.tap_tape_url2 = 'https://tap-dev-widget.s3.amazonaws.com/index.html#/'
    config.pusher_key = 'b3ed46dc4b4ff9192bef'

    /* DEVELOPMENT */

} else if (!process.env.NODE_ENV || process.env.NODE_ENV === 'staging') {

    /* STAGING */

    config.api_host_clear = 'https://api-staging.tap.st/'
    config.api_host = config.api_host_clear + 'v1'
    config.carusel_url = 'http://tap-dev-carousel-widget.s3-website.eu-west-2.amazonaws.com/#/'
    config.tap_page_url = 'http://tap-dev-carousel-widget.s3-website.eu-west-2.amazonaws.com/#//'
    config.tap_tape_url2 = 'http://tap-stage-tappage.s3-website.eu-west-2.amazonaws.com/#/'
    config.pusher_key = '5db5ca19614e8fbc4515'

    /* STAGING */

} else {

    /* PRODUCTION */

    config.api_host_clear = 'https://api.theaccessplatform.com/'
    config.api_host = config.api_host_clear + 'v1'
    config.carusel_url = 'https://carousel.theaccessplatform.com/index.html#/'
    config.tap_page_url = 'https://tap-dev-widget.s3.amazonaws.com/index.html#/'
    config.tap_tape_url2 = 'https://tappage.theaccessplatform.com/index.html#/'
    config.pusher_key = 'b467b31f50874ddbe4a1'

     /* PRODUCTION */

}

export default config
