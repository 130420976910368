import React, {PureComponent} from 'react';
import {reduxForm, Field} from 'redux-form';
import Sprite from '../../../../assets/img/symbol/sprite.svg';
import Textarea from 'react-textarea-autosize';

const renderField = ({input, accept, handleChange, value, placeholder, type, className, meta: {touched, error, warning}}) => (
    <input {...input} className={className} accept={accept} value={value} placeholder={placeholder} type={type}/>
);
const renderTextarea = ({placeholder, type, id, className, minRows, maxRows, onKeyUp, isDisabled}) => (
  <Textarea
    onKeyUp={onKeyUp}
    minRows={minRows}
    maxRows={maxRows}
    id={id}
    type={type}
    placeholder={placeholder}
    className={className}
    disabled={isDisabled}
  />
);

class SendMessageForm extends PureComponent {

    render() {

      const { handleSubmit, isDisabled } = this.props;

      const handleKeyDown = function (e, cb) {
          if (e.key === 'Enter' && e.shiftKey === false) {
              e.preventDefault();
              cb();
          }
      };

      return (
          <form action="#" onSubmit={handleSubmit} onKeyDown={(e) => { handleKeyDown(e, handleSubmit); }} className="chat-form" id="send-message-form">
          <Field
            name='text'
            component={renderTextarea}
            type='text'
            placeholder='Write a message…'
            className='chat-input'
            maxRows={3}
            minRows={1}
            id='content-messaging-textarea'
            isDisabled={isDisabled}
          />
          <button type="submit" className="chat-sbm">
              <svg className="icon icon-send">
                  <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={ Sprite + "#send" }></use>
              </svg>
          </button>
          </form>
      )
    }
}

export default reduxForm({
  form: 'send_message_form'
})(SendMessageForm);
