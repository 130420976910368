import HttpService from '../../system/services/HttpService'
import pusher from '../../system/services/PusherWrapper';

export default {
    get: (dialog_id) => {
        return HttpService.get('/interactionSite/dialogs/' + dialog_id + '/messages')
    },
    send: (type, text, dialog_id) => {
        return HttpService.post('/interactionSite/dialogs/' + dialog_id + '/messages/send', {
            type: type,
            text: text,
            socketId: pusher.connection.socket_id
        })
    }
};
