import React, {PureComponent} from 'react';

class ActionPopup extends PureComponent {

    render() {
        const {handleClose} = this.props;

        if(this.props.children)
            return this.props.children
        else
            return (
                <div className="general-popup" id="verification" >
                    <h2 className="modal-title">{this.props.title}</h2>
                    <p className="subtitle text-footer-modal">{this.props.body}</p>
                    <button type="submit" className="el-btn general-action-button" onClick={handleClose} >
                            Close
                    </button>
                </div>
            )
    }
}

export default ActionPopup;
