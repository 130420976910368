import API from "../../../api";
import { call, put } from "redux-saga/effects";
import {
    LOGIN_SUCCESS,
    LOGIN_FAILED,
    CLOSE_LOGIN_POPUP,
    ACCOUNT_INFO_FAILED,
    ACCOUNT_INFO_SUCCESS,
    ACCOUNT_INFO,
    UNIVERSITY_QUESTIONS_FAILED,
    UNIVERSITY_QUESTIONS_SUCCESS,
    SAVE_UNIVERSITY_QUESTIONS_FAILED,
    SAVE_UNIVERSITY_QUESTIONS_SUCCESS,
    REGISTRATION_FAILED,
    REGISTRATION_SUCCESS,
    LOGIN,
    GET_COUNTRIES_SUCCESS,
    GET_NATIONALITIES_SUCCESS,
    GET_COUNTRIES,
    ACCOUNT_CHANGE_AVATAR_SUCCESS
} from "../../actions/accountActions";

import {
    FIND_OR_CREATE_DIALOG
} from "../../actions/dialogsActions";

export async function checkEmail(email) {
    return await API.account.checkEmail(email);
}

export function * login(action) {
    const payload = action.payload;

    try {
        const response = yield call(() => {
            return API.account.login(payload.email, payload.password);
        });

        if (response.success) {
            localStorage.setItem("TapAccountInteractionSite", JSON.stringify(
                response.data.token
            ));

            yield put({
                type: LOGIN_SUCCESS,
                payload: {
                    token: response.data.token
                }
            });

            yield put({
                type: ACCOUNT_INFO
            });

            yield put({
                type: CLOSE_LOGIN_POPUP
            });

            yield put({
                type: FIND_OR_CREATE_DIALOG,
                payload: {
                    student_id: payload.student_id
                }
            });

            if (action.onSuccess) {
                action.onSuccess();
            }
        } else {
            yield put({
                type: LOGIN_FAILED
            });
        }
    } catch (e) {
        yield put({
            type: LOGIN_FAILED
        });
    }
};

export function * accountInfo() {
    try {
        const response = yield call(() => {
            return API.account.get();
        });

        if (response.success) {
            yield put({
                type: ACCOUNT_INFO_SUCCESS,
                payload: {
                    account: response.data.account,
                    notificationData: response.data.account.notificationData
                }
            });
        } else {
            yield put({
                type: ACCOUNT_INFO_FAILED
            });
        }
    } catch (e) {
        yield put({
            type: ACCOUNT_INFO_FAILED
        });
    }
}

export function * universityQuestions(action) {
    try {
        const response = yield call(() => {
            return API.account.universityQuestions(action.payload);
        });

        if (response.success) {
            yield put({
                type: UNIVERSITY_QUESTIONS_SUCCESS,
                payload: {
                    tags: response.data.tags,
                    quizQuestion: response.data.quizQuestion,
                    specializations: response.data.specializations
                }
            });

            for (const val of response.data.quizQuestion) {
                if (val.question === "What is your country of residence?") {
                    yield put({
                        type: GET_COUNTRIES
                    });
                }
            }
        } else {
            yield put({
                type: UNIVERSITY_QUESTIONS_FAILED
            });
        }
    } catch (e) {
        console.log(e);

        yield put({
            type: UNIVERSITY_QUESTIONS_FAILED
        });
    }
}

export function * saveUniversityQuestions(action) {
    try {
        const response = yield call(() => {
            return API.account.saveUniversityQuestions(action.payload);
        });

        if (response.success) {
            yield put({
                type: SAVE_UNIVERSITY_QUESTIONS_SUCCESS
            });
        } else {
            yield put({
                type: SAVE_UNIVERSITY_QUESTIONS_FAILED
            });
        }
    } catch (e) {
        console.log(e);

        yield put({
            type: SAVE_UNIVERSITY_QUESTIONS_FAILED
        });
    }
}

export function * registration(action) {
    const payload = action.payload;
    try {
        const response = yield call(() => {
            return API.account.registration(payload);
        });

        if (response.success) {
            yield put({
                type: REGISTRATION_SUCCESS
            });

            yield put({
                type: LOGIN,
                payload: {
                    email: payload.email,
                    password: payload.password,
                    student_id: payload.student_id
                },
                onSuccess: action.onLoginSuccess
            });
            if (action.onSuccess) {
                action.onSuccess();
            }
        } else {}
    } catch (e) {
        console.log(e);

        yield put({
            type: REGISTRATION_FAILED
        });
    }
}

export function * getCountries(action) {
    const payload = action.payload;
    try {
        const response = yield call(() => {
            return API.account.getCountries();
        });

        yield put({
            type: GET_COUNTRIES_SUCCESS,
            payload: {
                countries: response.data.tags
            }
        });
    } catch (e) {
        console.log(e);
    }
}

export function * getNationalities(action) {
    const payload = action.payload;
    try {
        const response = yield call(() => {
            return API.account.getNationalities();
        });

        yield put({
            type: GET_NATIONALITIES_SUCCESS,
            payload: {
                nationalities: response.data
            }
        });
    } catch (e) {
        console.log(e);
    }
}

export function * accountChangeAvatar(action) {
    const payload = action.payload;
    try {
        const response = yield call(() => {
            return API.account.changeAvatar(
                payload.avatar
            );
        });
        yield put({ type: ACCOUNT_INFO });
    } catch (e) {
        console.log(e);
    }
}
