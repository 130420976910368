import {connect} from 'react-redux';
import React from 'react';
import Notifications from 'react-notification-system-redux';
 
class NotificationsStack extends React.Component {

  render() {
    const notifications = this.props.notifications;
 
    const style = {
      NotificationItem: {
        DefaultStyle: {
          margin: '10px 5px 2px 1px'
        }
      }
    };
 
    return (
      <div>
        <Notifications
          notifications={notifications}
          style={style}
        />
        {this.props.children}
      </div>
    );
  }
}
 
export default connect(
  state => ({
      notifications: state.notifications 
    })
)(NotificationsStack);
